var ServiceTypes = [
    { value: 1, label: "INSTALLATION", },
    { value: 2, label: "REPAIR", },
    { value: 3, label: "UNINSTALLATION", },
    { value: 4, label: "REVISIT", }
];
//1=Intallation, 2=Repair, 3=Uninstallation,4=Revisit


var SearchTypes = [
    { value: 'fullName', label: "Customer Name", },
    { value: 'lastName', label: "Last Name", },
    { value: 'orderId', label: "Order Id", },
    { value: 'email', label: "Email", },
    { value: 'fullMobileNo', label: "Contact No", },
    { value: 'requestId', label: "Request Id", }
];

module.exports = {
    ServiceTypes,
    SearchTypes
}

import React, { Component }  from 'react';
import { getAllServicePolicies } from '../../config/services/servicePolicyService';
import { AdsService } from '../../config/services/AdsService';
import { toast } from 'react-toastify';
import Popup from "reactjs-popup"
import { LoaderSvg } from '../Reusable'
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";
import moment from 'moment';
import { DatePicker } from 'antd';
import Select from 'react-select';
import { Button, Modal, FormCheck} from 'react-bootstrap';
import dateFormat from 'dateformat';
import { Tag } from 'antd';
const { RangePicker } = DatePicker;


const allRedirectScreens = [
    {id:'LiveSessionListingScreen', name:'Live Session Listing Screen'},
    {id:'NotificationsListingScreen', name:'Notifications Listing Screen'},
    {id:'DietTab', name:'Diet Tab'},
    {id:'FitnessTab', name:'Fitness Tab'},
    {id:'HealthCoinTab', name:'Health Coin Tab'},
    {id:'OnlineServicesCategoryScreen', name:'Online Services Category Screen'},
    {id:'MyBookingUpcomingListingScreen', name:'My Booking Upcoming Listing Screen'},
    {id:'MyBookingHistoryListingScreen', name:'My Booking History Listing Screen'},
    {id:'LaunchFitwarz', name:'Launch Fitwarz'},
    {id:'FreeSessionsRemainingScreen', name:'Free Sessions Remaining Screen'},
    {id:'LaunchScenicWorkouts', name:'Scenic Workout Screen'},
    {id:'LaunchOnDemandWorkouts', name:'OnDemand Workout Screen'},

    {id:'/classes', name:'OFP WEBSITE CLASS'},
    {id:'/fitwarz-game', name:'OFP WEBSITE FITWARZ GAME'},
    {id:'/health-coins', name:'OFP WEBSITE HEALTH COINS'},
    {id:'/products', name:'OFP WEBSITE PRODUCTS'},
    {id:'/?id=WhatWeOffer', name:'OFP WEBSITE WHAT WE OFFER'},
    {id:'/about-us', name:'OFP WEBSITE ABOUT US'},
    {id:'/brand', name:'OFP WEBSITE BRAND'},
    {id:'/success-stories', name:'OFP WEBSITE SUCCESS STORIES'},
    {id:'/faq', name:'OFP WEBSITE FAQ'},
    {id:'/contact-us', name:'OFP WEBSITE CONTACT US'},
    {id:'/privacy', name:'OFP WEBSITE PRIVACY'},
    {id:'/terms-conditions', name:'OFP WEBSITE TERMSANDCONDITION'},
    {id:'/career', name:'OFP WEBSITE CAREER'},
    {id:'/blog', name:'OFP WEBSITE BLOGS'},
    {id:'/instructors', name:'OFP WEBSITE INSTRUCTORS'},

    
]

const websiteRedirectionScreens = [

    {id:'/classes', name:'OFP WEBSITE CLASS'},
    {id:'/fitwarz-game', name:'OFP WEBSITE FITWARZ GAME'},
    {id:'/health-coins', name:'OFP WEBSITE HEALTH COINS'},
    {id:'/products', name:'OFP WEBSITE PRODUCTS'},
    {id:'/?id=WhatWeOffer', name:'OFP WEBSITE WHAT WE OFFER'},
    {id:'/about-us', name:'OFP WEBSITE ABOUT US'},
    {id:'/brand', name:'OFP WEBSITE BRAND'},
    {id:'/success-stories', name:'OFP WEBSITE SUCCESS STORIES'},
    {id:'/faq', name:'OFP WEBSITE FAQ'},
    {id:'/contact-us', name:'OFP WEBSITE CONTACT US'},
    {id:'/privacy', name:'OFP WEBSITE PRIVACY'},
    {id:'/terms-conditions', name:'OFP WEBSITE TERMSANDCONDITION'},
    {id:'/career', name:'OFP WEBSITE CAREER'},
    {id:'/blog', name:'OFP WEBSITE BLOGS'},
    {id:'/instructors', name:'OFP WEBSITE INSTRUCTORS'},
    
]

const mobileRedirectionScreens = [

    {id:'LiveSessionListingScreen', name:'Live Session Listing Screen'},
    {id:'NotificationsListingScreen', name:'Notifications Listing Screen'},
    {id:'DietTab', name:'Diet Tab'},
    {id:'FitnessTab', name:'Fitness Tab'},
    {id:'HealthCoinTab', name:'Health Coin Tab'},
    {id:'OnlineServicesCategoryScreen', name:'Online Services Category Screen'},
    {id:'MyBookingUpcomingListingScreen', name:'My Booking Upcoming Listing Screen'},
    {id:'MyBookingHistoryListingScreen', name:'My Booking History Listing Screen'},
    {id:'LaunchFitwarz', name:'Launch Fitwarz'},
    {id:'FreeSessionsRemainingScreen', name:'Free Sessions Remaining Screen'},
    {id:'LaunchScenicWorkouts', name:'Scenic Workout Screen'},
    {id:'LaunchOnDemandWorkouts', name:'OnDemand Workout Screen'}
]



class Ads extends Component {
    constructor(props) {
        super(props);
        this.state = {    
            Loader: false,
            adSaveLoader: false,          
            allSearchServicePolicies: [],
            allServicePolicies: [],
            redirectScreens: [],
            platforms: [],
            statusOption: [],
            mediaOption: [],
            sortOption: [],
            AdsList: [],            
            tempAdsId: "",
            openAddAdsPopup: false,
            deleteAdsPopup: false,
            openEditAdsPopup: false,
            adEditLoader: false, 
            count: 16,
            status: 'Active',
            search: '',
            filter: '',
            searchStatus: 'Active',
            searchMediaType: '',
            searchPlatform: '',
            policy: '',
            sortType: '',
            sortField: '',
            totalAdsCount: 200,
            pageNo: 1,
            currentDate: moment().startOf('day').valueOf(),            
            selectDateFrom: moment().startOf('day').valueOf(),
            selectDateTo: moment().endOf('day').valueOf(),
            adsTitle: "",
            subTitle:"",
            type: "",
            dateFrom: '',
            dateTo: '',
            appliedPolicies: [],
            appliedPlatforms: [],
            uploadMediaType: 'image',
            redirectionType: 'Screen',
            previousUploadMediaType: '',
            redirection: '',
            redirectionKey:1,
            redirectionButtonText: '',
            adImagePrev: "",
            adVideoPrev: "",
            adImage: "",  
            adVideo: "",                       
            externalURL: "",  
            imagesViewPopup: "",          
            previewFiletype: "",          
            previewFileInfo: "",  
            displayOrder: 0,
            isActive: true,
            adsTypeOption : [
                {value:'banner', label:'Banner'},
                {value:'newLaunch', label:'New Launch'}
            ]
        }        
    }

    componentDidMount() {

        var allPlatforms = [
            {value:'', label:'All'},
            {value:'fitplusHealthClient', label:'Fitplus Health Mobile'},
            {value:'fitplusHealthTabletLandscape', label:'Fitplus Health Tablet Landscape'},
            {value:'fitplusHealthTabletPortrait', label:'Fitplus Health Tablet Portrait'},
            {value:'dieticianPanel', label:'Dietician Panel'},
            {value:'fitservApp', label:'Fitserv App'},
            {value:'onefitplusWebsite', label:'Onefitplus Website'},
            {value:'fitwarz', label:'Fitwarz'},
            {value:'onefitplusWebMob', label:'Onefitplus Web Mobile'}            
        ]

        var statusOption = [
            {value:'Active', label:'Active'},
            {value:'inActive', label:'inActive'},
            {value:'Deleted', label:'Deleted'}
        ]
        
        var mediaOption = [
            {value:'', label:'All'},
            {value:'image', label:'Image'},
            {value:'video', label:'Video'}
        ]

        var sortOption = [
            {value:'', label:'Default'},
            {value:'startDate_1', label:'Start Date (Ascending)'},
            {value:'startDate_-1', label:'Start Date (Descending)'},
            {value:'displayOrder_1', label:'Display Order (Ascending)'},
            {value:'displayOrder_-1', label:'Display Order (Descending)'}
        ]

        this.setState({redirectScreens: allRedirectScreens, platforms: allPlatforms, statusOption: statusOption, mediaOption: mediaOption, sortOption: sortOption})
        
        this.getAllServicePolicies();
        this.getAdsItems();        
    }

    getAllServicePolicies = () => {
        getAllServicePolicies()
          .then(res => {
            const response = res.data;
            let { allSearchServicePolicies, allServicePolicies } = this.state
            if (response.statusCode == 1) {
                const { result } = res.data.responseData

                let setPolicy = {};
                setPolicy.label = "All";
                setPolicy.value = '';
                allServicePolicies.push(setPolicy);
                allSearchServicePolicies.push(setPolicy);

                result.map(policy => {
                    if(policy.customerTypeId) {
                        let setPolicy = {};
                        setPolicy.label = policy.versionName + " (" + policy.customerTypeId.customerType + ") "
                        setPolicy.value = policy._id;                        
                        allServicePolicies.push(setPolicy);
                        allSearchServicePolicies.push(setPolicy);
                    }               
                })
                this.setState({ allServicePolicies: allServicePolicies })                
            }
            else if (response.statusCode == 0) {
              toast.error(response.error.errorMessage)
            }
          })
    }

    handleChangePolicy = (value) => {       
        let appliedPolicies = [];  
        let foundAll = false;
        if(value && value.length) {
            let getPolicyLength = parseInt(value.length) - parseInt(1);
            value.map((p,i) => {
                if(p.label != 'All') {
                    foundAll = true;
                    appliedPolicies.push(p);
                } else {
                    if(p.label == 'All' && i == getPolicyLength) {
                        appliedPolicies = [];  
                        let setPolicy = {};
                        setPolicy.label = "All";
                        setPolicy.value = '';
                        appliedPolicies.push(setPolicy);
                    }                    
                }
            })
        } else {
            let setPolicy = {};
            setPolicy.label = "All";
            setPolicy.value = '';
            appliedPolicies.push(setPolicy);
        }
        this.setState({ appliedPolicies });       
    }

    updateRedirectScreen = (value , isEdit) => {

        if(!isEdit){

            this.setState({redirection: '' , redirectionKey: this.state.redirectionKey + 1})

        }

        let checklist = {isMobile:false , isWeb:false , common:false}


        for (let i = 0; i < value.length; i++) {

            if(value[i].value == ''){
                this.setState({redirectScreens: allRedirectScreens})
                break; 
            }
            else if (value[i].value == 'onefitplusWebMob' || value[i].value == 'onefitplusWebsite'){
                checklist.isWeb = true
            }
            else if (value[i].value == 'fitplusHealthClient' || value[i].value == 'dieticianPanel' || value[i].value == 'fitservApp' || value[i].value == 'fitwarz'){
                checklist.isMobile = true
            }
            if(checklist.isWeb && checklist.isMobile){
                this.setState({redirectScreens: allRedirectScreens})
                break; 
            }

            if(i == value.length - 1){
                if(checklist.isWeb){
                    this.setState({redirectScreens: websiteRedirectionScreens})
                }
                else{
                    this.setState({redirectScreens: mobileRedirectionScreens})
                }
            }
    }
}

    handleChangePlatform = (value) => {   

        try {

            let appliedPlatforms = [];  
            let foundAll = false;
           
            if(value && value.length) {
               
                let getPolicyLength = parseInt(value.length) - parseInt(1);
                value.map((p,i) => {
                    if(p.label != 'All') {
                        foundAll = true;
                        appliedPlatforms.push(p);

                    } else {
                        if(p.label == 'All' && i == getPolicyLength) {
                            appliedPlatforms = [];  
                            let setPlatform = {};
                            setPlatform.label = "All";
                            setPlatform.value = '';
                            appliedPlatforms.push(setPlatform);
                        }                    
                    }
                })
            } else {
                let setPlatform = {};
                setPlatform.label = "All";
                setPlatform.value = '';
                appliedPlatforms.push(setPlatform);
                this.setState({redirectScreens: allRedirectScreens})
            }
            this.updateRedirectScreen(appliedPlatforms)

            this.setState({ appliedPlatforms });   
            
        } catch (error) {

            console.log(error);
            
        }
        
        
    }

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => this.getAdsItems());

    handleChangeActive = (val) => {
        this.setState({ isActive: val });

        if(val) {
            this.setState({ status: 'Active' }); 
        } else {
            this.setState({ status: 'inActive' });
        }           
    }

    handleChange = (e) => {
        this.setState({ error: '' })
        if (e.target.id == 'adImage') {
            let file = e.target.files[0];

            var mediaMimeTYpe = [];          
            if (file && file.type) {
                mediaMimeTYpe = file.type.split("/");                 
            }
            if(!mediaMimeTYpe.includes('image')) {
                this.setState({ error: "please upload valid image", adImage: "", adImagePrev: "" })
                e.target.value = null;
            } else {
                let reader = new FileReader();
                reader.onloadend = () => {
                    this.setState({
                        adImagePrev: reader.result
                    });
                }
                reader.readAsDataURL(file)
                this.setState({
                    adImagePrev: file
                })

                this.setState({
                    adImage: e.target.files[0]
                });
            }
            
        } else if (e.target.id == 'adVideo') {
            let file = e.target.files[0];
            var mediaMimeTYpe = [];          
            if (file && file.type) {
                mediaMimeTYpe = file.type.split("/");                 
            }
            if(!mediaMimeTYpe.includes('video')) {
                this.setState({ error: "please upload valid video", adVideo: "" })
                e.target.value = null;
            } else {
                this.setState({ adVideo: e.target.files[0] });  
            }
        } else {  
            if (e.target.id == 'redirectionType') {
                if(e.target.value == 'Screen') {
                    this.setState({ externalURL: '' });  
                }
                if(e.target.value == 'Link') {
                    this.setState({ redirection: '' });  
                }
            }
            this.setState({
                [e.target.id]: e.target.value,
                errors: false
            })
        }
    }

    closeModal = () => {  
        this.setState({
            error: "", deleteAdsPopup: false, openEditAdsPopup: false, tempAdsId: "", openAddAdsPopup: false, adsTitle: "", subTitle:"" , dateFrom: "", dateTo: "", redirection: "", redirectionButtonText: "",  uploadMediaType: "image", adImage: "", video: "", adVideo: "", adVideoPrev: "", adImagePrev: "", externalURL: "", imagesViewPopup: "", previewFiletype: "", previewFileInfo: "", appliedPolicies: [], appliedPlatforms: [], displayOrder: 0, isActive: true, status: 'Active',  redirectionType: 'Screen', type: ""
        })
    }  

    startDateChange = (dates, dateStrings) => {
        if(dates) {            
            this.setState({ setDateFrom: dates, dateFrom: moment(dates).startOf('day').valueOf(), titleErrorMsg: '' })
        } else {
            this.setState({ dateFrom: '', titleErrorMsg: 'Please Select start date' })
        }
    }

    endDateChange = (dates, dateStrings) => {
        if(dates) {
            this.setState({ setDateTo: dates, dateTo: moment(dates).endOf('day').valueOf(), titleErrorMsg: '' })
        } else {
            this.setState({ dateTo: '', titleErrorMsg: '' })
        }
    }

    onSearch = (e) => {
        const { value } = e.target;
        this.setState({ search: value }, () => {
            this.getAdsItems()
        })
    }


    filterByDate = (date) => {
        let selectDateFrom = null, selectDateTo = null;
        if (date) {
            selectDateFrom = moment(date[0]).startOf('day').valueOf()
            selectDateTo = moment(date[1]).endOf('day').valueOf()
        }
        this.setState({ selectDateFrom, selectDateTo, apiCalled: true, pageNo: 1 }, () => {
             this.getAdsItems() 
        })
    }
    filterByStatus = (e) => {
        this.setState({ searchStatus: e. value }, () => {
            this.getAdsItems()
        })
    }
    filterByPolicy = (policy) => {
        this.setState({ policy: policy.value }, () => {
            this.getAdsItems()
        })
    } 
    filterMediaType = (media) => {
        this.setState({ searchMediaType: media.value }, () => {
            this.getAdsItems()
        })
    } 
    filterPlatform = (platform) => {       
        this.setState({ searchPlatform: platform.value }, () => {
            this.getAdsItems()
        })
    } 
    sortByField = (s) => {
        var r = s.value.split("_");  
        this.setState({ sortField: r[0], sortType: r[1] }, () => {
            this.getAdsItems()
        })
    } 
    
    
    // cleatFilter = () => {
    //     // searchStatus: 'Active', search: ''
    //     this.setState({ selectDateFrom: moment().startOf('day').valueOf(), selectDateTo: moment().endOf('day').valueOf(),  searchMediaType: '', searchPlatform: '', policy: '' }, () => {
    //         this.getAdsItems()
    //     })
    // }
    
    getAdsItems = (params) => {
        let self = this;
        this.setState({ Loader: true })
        const { count, search, policy, searchMediaType, searchPlatform, pageNo, searchStatus, currentDate,selectDateFrom,selectDateTo,sortType,sortField } = this.state;

        params = {
            params: {
                search,
                count,
                policy,
                pageNo: pageNo - 1,
                status: searchStatus,
                currentDate: currentDate,
                dateFrom: selectDateFrom,
                dateTo: selectDateTo,
                searchPlatform,
                searchMediaType,
                sortType,
                sortField
            }
        }

        AdsService.getAllAds(params)
            .then(function (res) {                
                let response = res.data;
                if (response.statusCode == 1) {
                    self.setState({
                        AdsList: response.responseData.ads,
                        totalAdsCount: response.responseData.adsCount,
                        Loader: false
                    })
                } else if (response.statusCode == 0) {
                    self.setState({ Loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    handledeleteAds = (data, event) => {
        let tempAdsId = data._id;
        this.setState({ tempAdsId, deleteAdsPopup: true })
    }

    deleteAds = () => {
        const { tempAdsId } = this.state
        let self = this;
        this.setState({ Loader: true })

        let paramsObj = {
            adsId: tempAdsId
        }

        AdsService.deleteAds(paramsObj)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.closeModal();
                    self.getAdsItems();
                    self.setState({ Loader: false })
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    self.setState({ error: response.error.errorMessage, Loader: false })
                }
            })
    }

    validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
      }

    handleAddAds = () => {
        const { appliedPolicies, appliedPlatforms } = this.state;
        let setDefaultOption = {};
        setDefaultOption.label = "All";
        setDefaultOption.value = '';
        appliedPolicies.push(setDefaultOption);
        appliedPlatforms.push(setDefaultOption);        
        this.setState({ openAddAdsPopup: true})
    }

    submitCreateAds = (e) => {
        e.preventDefault();
        const { adsTitle,  subTitle , dateFrom, dateTo, appliedPolicies, appliedPlatforms, redirectionType, redirection, redirectionButtonText, adImage, adVideo, externalURL, uploadMediaType, status, displayOrder,type } = this.state;
              
        let policyIds = [];
        if(appliedPolicies.length > 0) {            
            appliedPolicies.map((policy, i) => {                 
                if(policy.value.toString()) {              
                    policyIds.push(policy.value.toString());
                }
            })
        }   

        let platforms = [];
        if(appliedPlatforms.length > 0) {            
            appliedPlatforms.map((p, i) => {                 
                if(p.value.toString()) {              
                    platforms.push(p.value.toString());
                }
            })
        } 
        
        let paramsObj = new FormData();       
        paramsObj.append('title', adsTitle)
        paramsObj.append('subTitle', subTitle)
        paramsObj.append('startDate', dateFrom)
        paramsObj.append('endDate', dateTo)
        if(policyIds.length > 0) { 
            paramsObj.append('policyIds', policyIds)
        }
        if(platforms.length > 0) { 
            paramsObj.append('platform', platforms)
        }
        paramsObj.append('redirection', redirection)
        paramsObj.append('redirectionButtonText', redirectionButtonText)
        paramsObj.append('image', adImage)
        paramsObj.append('video', adVideo)
        paramsObj.append('redirectionType', redirectionType)
        paramsObj.append('externalURL', externalURL)
        paramsObj.append('uploadMediaType', uploadMediaType)
        paramsObj.append('status', status)
        paramsObj.append('displayOrder', displayOrder)
        paramsObj.append('type', type) // adsType

        var mediaMimeTYpe = [];
        if (uploadMediaType) {
            if (uploadMediaType == "image" && adImage && adImage.type) {
                mediaMimeTYpe = adImage.type.split("/");                 
            }
            if (uploadMediaType == "video" && adVideo && adVideo.type) {
                mediaMimeTYpe = adVideo.type.split("/");                 
            }
        }

        if (!dateFrom) {
            this.setState({ error: "Start date can't be empty!" })
        } else if (dateTo && dateTo < dateFrom) {
            this.setState({ error: "End date should be greater than start date." })
        } else if (!uploadMediaType) {
            this.setState({ error: "Please select upload media type" })
        } else if (uploadMediaType == "image" && !adImage) {
            this.setState({ error: "Please select image file" })
        } else if (uploadMediaType == "image" && !mediaMimeTYpe.includes('image')) {
            this.setState({ error: "Please select valid image file" })
        } else if (uploadMediaType == "video" && !adVideo) {
            this.setState({ error: "Please select video" })
        } else if (uploadMediaType == "video" && !mediaMimeTYpe.includes('video')) {
            this.setState({ error: "Please select valid video" })
        } else if (redirectionType == 'Screen' && !redirection) {
            this.setState({ error: "Please select redirect screen" })
        }  else if (redirectionType == 'Link' && !externalURL) {
            this.setState({ error: "Please enter external link" })
        } else if (uploadMediaType == "video" && !redirectionButtonText) {
            this.setState({ error: "Button text can't be empty" })
        } else if (!type){
            this.setState({ error: "Ads type can't be empty!" })
        }
        else if (externalURL && !this.validURL(externalURL)) {
            this.setState({ error: "please enter valid external link" })
        } else {
            this.submitCreateAdsApiCall(paramsObj);
        }
    }

    submitCreateAdsApiCall = (params) => {
        let self = this;
        this.setState({ adSaveLoader: true })
        AdsService.createAd(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.closeModal();
                    self.getAdsItems();

                    toast.success('Ads created successfully')
                    self.setState({ adSaveLoader: false })

                } else if (response.statusCode == 0) {
                    var error = response.error.errorMessage;
                    if(response.error && response.error.errors && response.error.errors[0] && response.error.errors[0]['message']) {
                        error = response.error.errors[0]['message'];
                    }                    
                    self.setState({ error: error, adSaveLoader: false })
                }
            })
    }
    
    handleEditAds = (data, event) => {

        let appliedPolicies = [];      
        if(data.policyData && data.policyData.length > 0) {
            data.policyData.map(policy => {
                if(policy.customerTypeId) {
                    let setPolicy = {};
                    let foundPolicy = this.state.allServicePolicies.filter(function (p) { return p.value == policy._id });
                    setPolicy.label = foundPolicy && foundPolicy.length > 0 && foundPolicy[0]?foundPolicy[0].label:policy.versionName + " (" + policy.customerTypeId.customerType + ") "
                    setPolicy.value = policy._id;
                    appliedPolicies.push(setPolicy);
                }               
            })
        } else {
            let setPolicy = {};
            setPolicy.label = "All";
            setPolicy.value = '';
            appliedPolicies.push(setPolicy);
        }

        let appliedPlatforms = [];
        if(data.platform && data.platform.length > 0) {
            data.platform.map(p => {
                this.state.platforms.map(platform => {
                    if(platform.value == p) {                        
                        let setPolicy = {};
                        setPolicy.label = platform.label
                        setPolicy.value = platform.value
                        appliedPlatforms.push(setPolicy); 
                    }                    
                })                              
            })
        } else {
            let setPolicy = {};
            setPolicy.label = "All";
            setPolicy.value = '';
            appliedPlatforms.push(setPolicy);
        }

        this.setState({
            openEditAdsPopup: true,
            tempAdsId: data._id,
            adsTitle: data.title ? data.title : '',
            subTitle: data.subTitle ? data.subTitle : '',
            type: data.type ? data.type : '',
            dateFrom: data.startDate ? data.startDate : '',
            dateTo: data.endDate ? data.endDate : '',            
            appliedPolicies: appliedPolicies,
            appliedPlatforms: appliedPlatforms,
            redirection: data.redirection ? data.redirection : '',
            redirectionButtonText: data.redirectionButtonText ? data.redirectionButtonText : '',
            adImagePrev: data.imageUrl ? data.imageUrl : '',
            adVideoPrev: data.video ? data.video : '',
            redirectionType: data.redirectionType ? data.redirectionType : 'Screen',
            externalURL: data.externalURL ? data.externalURL : '',
            previousUploadMediaType: data.uploadMediaType ? data.uploadMediaType : '',
            uploadMediaType: data.uploadMediaType ? data.uploadMediaType : '',
            displayOrder: data.displayOrder ? data.displayOrder : 0,
            status: data.status ? data.status : '',
            adImage: '',
            adVideo: '',
            previewFileInfo:data
        })

        if(data.status == 'Active' || data.status == 'Deleted') {
            this.setState({isActive: true})
        } else {
            this.setState({isActive: false})
        }

        this.updateRedirectScreen(appliedPlatforms , true)

    }

    editAdsSubmit = (e) => {
        e.preventDefault();
        const { tempAdsId, adsTitle,  subTitle ,  dateFrom, dateTo, redirection, redirectionButtonText, adImage, adVideo, externalURL, appliedPolicies, appliedPlatforms, previousUploadMediaType, uploadMediaType , status, displayOrder, redirectionType, type} = this.state;

        let policyIds = [];
        if(appliedPolicies && appliedPolicies.length > 0) {            
            appliedPolicies.map((policy, i) => {   
                if(policy.value && policy.value.toString()) {              
                    policyIds.push(policy.value.toString());
                }
            })
        }   

        let platforms = [];
        if(appliedPlatforms.length > 0) {            
            appliedPlatforms.map((p, i) => {                 
                if(p.value.toString()) {              
                    platforms.push(p.value.toString());
                }
            })
        } 

        let paramsObj = new FormData();
        paramsObj.append('adsId', tempAdsId)
        paramsObj.append('title', adsTitle)
        paramsObj.append('subTitle', subTitle)
        paramsObj.append('startDate', dateFrom)
        paramsObj.append('endDate', dateTo)
        if(policyIds.length > 0) { 
            paramsObj.append('policyIds', policyIds)
        }
        if(platforms.length > 0) { 
            paramsObj.append('platform', platforms)
        }
        
        paramsObj.append('image', adImage)
        paramsObj.append('video', adVideo)
        paramsObj.append('redirectionType', redirectionType)

        paramsObj.append('redirection', redirection)
        paramsObj.append('redirectionButtonText', redirectionButtonText)
        paramsObj.append('externalURL', externalURL)
        paramsObj.append('uploadMediaType', uploadMediaType)
        paramsObj.append('status', status)
        paramsObj.append('displayOrder', displayOrder)
        paramsObj.append('type', type)

        var mediaMimeTYpe = [];
        if (uploadMediaType) {
            if (uploadMediaType == "image" && adImage && adImage.type) {
                mediaMimeTYpe = adImage.type.split("/");                 
            }
            if (uploadMediaType == "video" && adVideo && adVideo.type) {
                mediaMimeTYpe = adVideo.type.split("/");                 
            }
        }

        if (!dateFrom) {
            this.setState({ error: "Start date can't be empty!" })
        } else if (dateTo && dateTo < dateFrom) {
            this.setState({ error: "End date should be greater than start date." })
        } else if (!uploadMediaType) {
            this.setState({ error: "Please select upload media type" })
        } else if (previousUploadMediaType == "video" && uploadMediaType == "image" && !adImage) {
            this.setState({ error: "please select image file" })
        } else if (previousUploadMediaType == "video" && uploadMediaType == "image" && !mediaMimeTYpe.includes('image')) {
            this.setState({ error: "please select valid image file" })
        } else if (previousUploadMediaType == "image" && uploadMediaType == "video" && !adVideo) {
            this.setState({ error: "please select video" })
        } else if (previousUploadMediaType == "image" && uploadMediaType == "video" && !mediaMimeTYpe.includes('video')) {
            this.setState({ error: "please select valid video" })
        } else if (redirectionType == 'Screen' && !redirection) {
            this.setState({ error: "Please select redirect screen" })
        }  else if (redirectionType == 'Link' && !externalURL) {
            this.setState({ error: "Please enter external link" })
        } else if (uploadMediaType == "video" && !redirectionButtonText) {
            this.setState({ error: "Button text can't be empty" })
        }else if (!type){
            this.setState({ error: "Ads type can't be empty!" })
        } else if (externalURL && !this.validURL(externalURL)) {
            this.setState({ error: "please enter valid external link" })
        }  else {
            this.setState({ error: "", adEditLoader: true })
            this.editAdsApiCall(paramsObj)
        } 
        
    }

    editAdsApiCall = (params) => {
        let self = this;
        AdsService.editAds(params)
            .then(function (res) {
                let response = res.data;
                if (response.statusCode == 1) {
                    self.closeModal();
                    self.getAdsItems();
                    self.setState({ adEditLoader: false })
                    toast.success(response.responseData.message)
                } else if (response.statusCode == 0) {
                    self.setState({ error: response.error.errorMessage, adEditLoader: false })
                }
            })
    }

    openImageVideoView = (type, file) => {
        this.setState({ imagesViewPopup: true, previewFiletype: type, previewFileInfo: file, validationError: '', validationSuccess: '' })
    }


    render() {

        // currentDate, dateFrom, dateTo, selectDateFrom, selectDateTo 
        const { error,  subTitle , count, pageNo,  status, searchStatus, redirectScreens, adsTypeOption, platforms, allSearchServicePolicies, allServicePolicies, totalAdsCount, AdsList, openAddAdsPopup,  adsTitle, adImage, adImagePrev, adSaveLoader, Loader, deleteAdsPopup, openEditAdsPopup, adEditLoader, redirection, redirectionButtonText, externalURL, imagesViewPopup, previewFiletype, previewFileInfo, appliedPolicies, uploadMediaType, appliedPlatforms, displayOrder, isActive, selectDateFrom, selectDateTo, redirectionType, type } = this.state;
    console.log("redirection:::" , redirection);


        return (
            <div className="body-container-wrapper">
                <div className="body-container">

                    { Loader ? <div className="loader_wrapper"><LoaderSvg /></div> : null }

                    <div className="users_header mb-3">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-between">
                                <h4 className="cm_page_heading">Ads</h4>
                                <div>
                                    <button onClick={this.handleAddAds} className="btn btn-primary">Create Ads</button>
                                </div>                                  
                            </div>

                            <div>
                                <div className="align-items-baseline d-inline-flex">           
                                    <ul className="list-inline mb-0">

                                        <li className="list-inline-item mt-2">
                                            <label inline className="mr-2">Filter By Date:</label>
                                            <RangePicker 
                                                style={{ height: "2.8em" }}
                                                size={"middle"}
                                                onChange={(val) => this.filterByDate(val)}
                                                // value={selectDateFrom && selectDateTo && [moment(selectDateFrom), moment(selectDateTo)]}
                                                defaultValue={[moment(selectDateFrom), moment(selectDateTo)]}
                                            />
                                        </li>

                                        <li className="list-inline-item mt-4 width-200">
                                            <Select
                                                isSearchable
                                                placeholder='Sort By Field'
                                                options={this.state.sortOption}
                                                onChange={(val) => this.sortByField(val)}
                                            />  
                                        </li>

                                        <li className="list-inline-item mt-4 width-200">
                                            <Select
                                                isSearchable
                                                placeholder='Select Media Type'
                                                options={this.state.mediaOption}
                                                onChange={(val) => this.filterMediaType(val)}
                                                isClearable={true}
                                            />  
                                        </li>

                                        <li className="list-inline-item mt-4 width-200">
                                            <Select
                                                isSearchable
                                                placeholder='Search Platforms'
                                                options={platforms}
                                                onChange={(val) => this.filterPlatform(val)}
                                            />  
                                        </li>

                                        <li className="list-inline-item mt-4 width-200">
                                            <Select
                                                isSearchable
                                                placeholder='Search Policy'
                                                options={allSearchServicePolicies}
                                                onChange={(val) => this.filterByPolicy(val)}
                                            />
                                        </li>
                                        
                                        <li className="list-inline-item mt-4 width-150">
                                            <Select
                                                isSearchable
                                                placeholder='Select Status'
                                                options={this.state.statusOption}
                                                onChange={(val) => this.filterByStatus(val)}
                                            />  
                                        </li>

                                        {/* <li className="list-inline-item mt-2">
                                            <button className="btn btn-dark mr-3" onClick={() => this.cleatFilter()}>Clear Filters</button>
                                        </li>  */}

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        {AdsList.map((Ad, index) => (
                            <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
                                <div className="card card_style">

                                    {Ad.video && Ad.video.url?                                   
                                        <div className="videoreel" onClick={() => this.openImageVideoView('video', Ad)}>
                                            <img className="card-img-top" src={Ad.imageUrl ? Ad.imageUrl : noImage} alt="Ads image" height="250" />
                                            <i class="fa fa-video-camera"></i>
                                        </div>
                                    :
                                        <div className="imagereel" onClick={() => this.openImageVideoView('image', Ad)}>
                                            <img className="card-img-top" src={Ad.imageUrl ? Ad.imageUrl : noImage} alt="Ads image" height="250" />
                                        </div>
                                    }
                                    
                                    <div className="p-3 text-center">
                                        <h4 className="card-title three_dots" title={Ad.title}>{Ad.title}</h4>
                                       
                                        <h4 className="card-title three_dots" >
                                            {`${dateFormat(Ad.startDate, "d/mm/yyyy")}`}

                                            {Ad.endDate?
                                                <> - {`${dateFormat(Ad.endDate, "d/mm/yyyy")}`} </>
                                            :null}
                                        </h4>

                                        <h4 className="card-title" >
                                            {Ad.platform && Ad.platform.length > 0?
                                                Ad.platform.map(p => 
                                                    this.state.platforms.map(platform => 
                                                        <>
                                                        {platform.value == p?                     
                                                            <span> {platform.label} </span>
                                                        :null}   
                                                        </>            
                                                    )                              
                                                )
                                            :null}
                                        </h4>

                                        {Ad.policyData && Ad.policyData.length?
                                            <>
                                            <h4 className="card-title" > Policy: </h4>
                                            {Ad.policyData.map((p) =>
                                                <>
                                                <span>
                                                    <Tag color="geekblue">{p.versionName}</Tag>
                                                </span>
                                                </>
                                            )}                                      
                                            </>
                                        :null}

                                        {!Ad.isDeleted?
                                        <>
                                            <div>
                                         
                                            <button className="btn btn-primary btn-xs" onClick={this.handleEditAds.bind(this, Ad)}><i className="fa fa-pencil-square-o"></i></button>
                                            <button className="btn btn-danger btn-xs ml-2" onClick={this.handledeleteAds.bind(this, Ad)}><i className="fa fa-trash"></i></button>
                                            </div>
                                        </>
                                        :null}
                                    </div>
                                    
                                </div>
                            </div>
                        ))}
                    </div>

                    {!AdsList.length ? <h3 className="empty_error">Sorry, we couldn't find any content!</h3> : null}

                    {totalAdsCount > this.state.count?            
                    <Pagination
                        activePage={pageNo}
                        itemsCountPerPage={count}
                        totalItemsCount={totalAdsCount}
                        pageRangeDisplayed={3}
                        onChange={this.handlePageChange}
                    />
                    :null}

                </div>


                <Popup
                    open={openAddAdsPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal big_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                        </span>
                        <h3 className="text-center mb-2 ">Add New Ads</h3>

                        <form onSubmit={this.submitCreateAds} className="modal-custom-bar">
                            <div className="form-group">
                                <label>Ads Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="adsTitle"
                                    id="adsTitle"
                                    placeholder="Ads Title"
                                />
                            </div>

                            <div className="form-group">
                                <label>Ads SubTitle</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="subTitle"
                                    id="subTitle"
                                    placeholder="Ads Sub Title"
                                />
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label>Start Date (*) </label>
                                    <DatePicker
                                        selected={this.state.dateFrom}
                                        onChange={this.startDateChange}
                                        className="d-block"
                                        dropdownClassName="upar_index"
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>End Date</label>
                                    <DatePicker
                                        minDate={this.state.setDateFrom}
                                        className="d-block"
                                        onChange={this.endDateChange}
                                        dropdownClassName="upar_index"
                                    />                                   
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label className="d-block">Policy</label>
                                    <Select
                                        isMulti
                                        isSearchable
                                        placeholder='Select Policy'
                                        options={allServicePolicies}
                                        onChange={(val) => this.handleChangePolicy(val)}
                                        value={appliedPolicies}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label>Upload Media Type (*)</label>
                                </div>

                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="uploadMediaType"  name="uploadMediaType" value="image" defaultChecked />
                                    <label className="ml-3">Image</label>
                                </div>
                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="uploadMediaType"  name="uploadMediaType" value="video" />
                                    <label className="ml-3">Video</label>
                                </div>
                            </div>
                            
                            <div className="form-group">
                                <label className="d-block">
                                {uploadMediaType == 'image' ? "Upload Ads Image (*)" : "Upload Ads Thumbnail"}
                                </label>
                                <input type="file" onChange={this.handleChange} id="adImage" className="form-control" name="adImage" />
                                {this.state.adImagePrev ? <img width="200px" src={this.state.adImagePrev} className="mt-2" /> : null}
                            </div>                                

                            {uploadMediaType == 'video' ?                       
                                <div className="form-group">
                                    <label className="d-block">Upload Ads Video (*)</label>
                                    <input type="file" onChange={this.handleChange} id="adVideo" className="form-control" name="adVideo" />
                                </div>
                            :null}                                                     

                            <div className="row">
                                <div className="form-group col">
                                    <label>Redirect (*)</label>
                                </div>

                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="redirectionType"  name="redirectionType" value="Screen" defaultChecked />
                                    <label className="ml-3">Screen</label>
                                </div>
                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="redirectionType"  name="redirectionType" value="Link" />
                                    <label className="ml-3">External Link</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label className="d-block">Platform(*)</label>                                   
                                    <Select
                                        isMulti
                                        isSearchable
                                        placeholder='Select Platforms'
                                        options={platforms}
                                        onChange={(val) => this.handleChangePlatform(val)}
                                        value={appliedPlatforms}
                                    />
                                </div>
                            </div>

                            {redirectionType == 'Screen'?
                                <div className="row">
                                    <div className="form-group col">
                                        <label className="d-block">Redirect Screen (*)</label>
                                        <select key={this.state.redirectionKey} className="form-control" onChange={this.handleChange} id="redirection">
                                            <option value='' selected > Select Redirect Screen... </option>
                                            {redirectScreens.map((redirectscreen, index) => (
                                                <option value={redirectscreen.id} > {redirectscreen.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            :null}

                            {redirectionType == 'Link'?                        
                                <div className="form-group">
                                    <label>
                                        External Link (*)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="externalURL"
                                        id="externalURL"
                                    />
                                </div>
                            :null}
                                    
                                        
                            {/* Enable "CTA Button" for image and video both  */}

                            
                                <div className="form-group">
                                    <label>
                                        Button Text (*)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="redirectionButtonText"
                                        id="redirectionButtonText"
                                    />
                                </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label className="d-block">Ads Type (*)</label>
                                    <select className="form-control" onChange={this.handleChange} id="type" name="type">
                                        <option selected disabled> Select Ads Type... </option>
                                        {
                                            adsTypeOption.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value} > {`${item.label}`} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                          


                            <div className="row">
                                <div className="form-group col">
                                    <label> Display Order </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="displayOrder"
                                        id="displayOrder"
                                        value={displayOrder}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Status</label>
                                    <FormCheck
                                        type="checkbox"
                                        label={status}
                                        name="isActive"
                                        checked={isActive}
                                       onChange={() => this.handleChangeActive(!isActive)}
                                    />                            
                                </div>
                            </div>

                            
                            { error ? <h4 className="text-center mb-2  error_message">{error}</h4> : '' }

                            <button className="btn btn-primary btn-block mt-4" disabled={adSaveLoader}>
                                {!adSaveLoader ? <span>Create Ads</span> :
                                    <div>
                                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                                        <span>Saving....</span>
                                    </div>
                                }
                            </button>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={openEditAdsPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal big_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                        </span>
                        <h3 className="text-center mb-2">Edit Ads </h3>

                        <form onSubmit={this.editAdsSubmit} className="modal-custom-bar">
                            <div className="form-group">
                                <label>Ads Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="adsTitle"
                                    id="adsTitle"
                                    value={adsTitle}
                                />
                            </div>

                            <div className="form-group">
                                <label>Ads Sub Title</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={this.handleChange}
                                    name="subTitle"
                                    id="subTitle"
                                    value={subTitle}
                                />
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label>Start Date</label>
                                    <DatePicker
                                        defaultValue={moment(new Date(this.state.dateFrom))}
                                        className="d-block"
                                        onChange={this.startDateChange}
                                        dropdownClassName="upar_index"
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>End Date</label>
                                    <DatePicker
                                        defaultValue={this.state.dateTo?moment(new Date(this.state.dateTo)):''}
                                        className="d-block"
                                        onChange={this.endDateChange}
                                        dropdownClassName="upar_index"
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label className="d-block">Policy</label>
                                    <Select
                                        isMulti
                                        isSearchable
                                        placeholder='Select Policy'
                                        options={allServicePolicies}
                                        onChange={(val) => this.handleChangePolicy(val)}
                                        value={appliedPolicies}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label>Upload Media Type (*)</label>
                                </div>

                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="uploadMediaType"  name="uploadMediaType" value="image" defaultChecked={uploadMediaType == 'image' ?true:false} />
                                    <label className="ml-3">Image</label>
                                </div>
                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="uploadMediaType"  name="uploadMediaType" value="video" defaultChecked={uploadMediaType == 'video' ?true:false} />
                                    <label className="ml-3">Video</label>
                                </div>
                            </div>

                            
                            <div className="form-group">
                                <label className="d-block">
                                {uploadMediaType == 'image' ? "Upload Ads Image (*)" : "Upload Ads Thumbnail"}
                                </label>
                                <input type="file" onChange={this.handleChange} id="adImage" className="form-control" name="adImage" />
                                <img width="200px" className="mt-2" src={adImagePrev ? adImagePrev : adImage} />
                            </div>
                            
                            {uploadMediaType == 'video' ?                            
                                <div className="form-group">
                                    <label className="d-block">Upload Ads Video (*)</label>
                                    <input type="file" onChange={this.handleChange} id="adVideo" className="form-control" name="adVideo" />
                                    {/* 
                                    {previewFileInfo && previewFileInfo.video && previewFileInfo.video.url?
                                    <video
                                        className="cm_video"
                                        src={previewFileInfo.video.url}
                                        type={previewFileInfo.video.mimetype}
                                        poster={previewFileInfo.imageUrl}
                                        controls
                                    />  
                                    :null} 
                                    */}
                                </div>
                            :null}
                            
                            <div className="row">
                                <div className="form-group col">
                                    <label>Redirect (*)</label>
                                </div>

                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="redirectionType"  name="redirectionType" value="Screen" defaultChecked={redirectionType == 'Screen' ?true:false} />
                                    <label className="ml-3">Screen</label>
                                </div>
                                <div className="form-group col">                                    
                                    <input type="radio" onChange={this.handleChange} id="redirectionType"  name="redirectionType" value="Link" defaultChecked={redirectionType == 'Link' ?true:false}/>
                                    <label className="ml-3">External Link</label>
                                </div>
                            </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label className="d-block">Platform(*)</label>
                                    <Select
                                        isMulti
                                        isSearchable
                                        placeholder='Select Platforms'
                                        options={platforms}
                                        onChange={(val) => this.handleChangePlatform(val)}
                                        value={appliedPlatforms}
                                    />  
                                </div>
                            </div>   

                            {redirectionType == 'Screen'?
                                <div className="row">
                                    <div className="form-group col">
                                        <label className="d-block">Redirect Screen (*)</label>
                                        <select className="form-control" onChange={this.handleChange} id="redirection" value={redirection}>
                                            <option value='' selected={!redirection} > Select Redirect Screen... </option>
                                            {redirectScreens.map((redirectscreen, index) => (
                                                <option value={redirectscreen.id} > {redirectscreen.name} </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            :null}
               
                            {redirectionType == 'Link'?                        
                                <div className="form-group">
                                    <label>
                                        External Link (*)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="externalURL"
                                        id="externalURL"
                                        value={externalURL}
                                    />
                                </div>
                            :null}
                             {/* Enable "CTA Button" for image and video both  */}
                           
                                <div className="form-group">
                                    <label>
                                        Button Text (*)
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="redirectionButtonText"
                                        id="redirectionButtonText"
                                        value={redirectionButtonText}
                                    />
                                </div>

                            <div className="row">
                                <div className="form-group col">
                                    <label className="d-block">Ads Type (*)</label>
                                    <select className="form-control" onChange={this.handleChange} id="type" value={type}>
                                        <option selected disabled> Select Ads Type... </option>
                                        {
                                            adsTypeOption.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.value} > {`${item.label}`} </option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </div>


                            <div className="row">
                                <div className="form-group col">
                                    <label> Display Order </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={this.handleChange}
                                        name="displayOrder"
                                        id="displayOrder"
                                        value={displayOrder}
                                    />
                                </div>

                                <div className="form-group col">
                                    <label>Status</label>
                                    <FormCheck
                                        type="checkbox"
                                        label={status}
                                        name="isActive"
                                        checked={isActive}
                                       onChange={() => this.handleChangeActive(!isActive)}
                                    />                            
                                </div>
                            </div>

                            {error ? <h4 className="text-center mb-4 mt-5 error_message">{error}</h4> : ''}

                            < button type="submit" className="btn btn-primary btn-block mt-4" disabled={adEditLoader}>
                                {!adEditLoader ? <span>Update</span> :
                                    <div>
                                        <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                                        <span>Updating....</span>
                                    </div>
                                }
                            </button>
                        </form>
                    </div>
                </Popup>

                <Popup
                    open={deleteAdsPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>
                            &times;
                        </span>

                        {error ?<h4 className="text-center mb-4 mt-5 error_message">{error}</h4> : ''}

                        <h3 className="text-center mb-4">Are you sure, you want to delete this Ads?</h3>
                        <div className="btn_group">
                            <div className="row">
                                <div className="col"><button className="btn btn-danger btn-sm btn-block" onClick={this.deleteAds}>Delete</button></div>
                                <div className="col"><button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button></div>
                            </div>
                        </div>
                    </div>
                </Popup>
                

                {imagesViewPopup && <Modal backdrop="static" show={imagesViewPopup} onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <h6>
                                Ads {previewFiletype == 'video' ?'Video':'Image'}
                            </h6> 
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body  text-center">
                                <div className="form-group">
                                    <div className='mt-2'>                                        
                                        <div className="img_gallery">                                            
                                            <div className="fullimgslider">
                                                {previewFiletype == 'video' ?
                                                    <video
                                                        className="cm_video"
                                                        src={previewFileInfo.video.url}
                                                        type={previewFileInfo.video.mimetype}
                                                        poster={previewFileInfo.imageUrl}
                                                        controls
                                                    />                                                                
                                                    : 
                                                    <img title='Before Image' src={previewFileInfo.imageUrl}  />                                                                
                                                }
                                            </div>
                                        </div>                                            
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Close</Button>
                    </Modal.Footer>
                </Modal>} 
            </div >
        )
    }
}


export default Ads;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup"
import dateFormat from 'dateformat';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import noImage from '../../assets/images/no-image.jpg'
import Pagination from "react-js-pagination";
import { addRole, getRoles, editRole, deleteRole } from '../../config/services/rolesService';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Menus } from '../template/Menus'
import SearchSpinner from '../common/SearchSpinner';


class Roles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            rolesList: [],
            accessiblePaths: [],
            roleName: '',
            search: '',
            count: 10,
            totalRoles: 15,
            pageNo: 1,
            sortType: '',
            sortField: '',
            AddRolePopup: false,
            deleteRolePopup: false,
            viewRolePopup: false,
            title: '',
            imagePreview: '',
            titleErrorMsg: '',
            editRolePopup: false,
            tempRoleId: "",
            searchLoader: false
        }
    }


    componentDidMount() {
        this.getRolesList();
    }


    handlePaths = (paths) => {
        this.setState({ accessiblePaths: paths })
    }


    getRolesList = () => {
        let { search, count, pageNo, sortType, sortField } = this.state;
        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField
            }
        }
        getRoles(paramsObj)
            .then(res => {
                let response = res.data
                if (response.statusCode == 1) {
                    this.setState({
                        rolesList: response.responseData.result,
                        totalRoles: response.responseData.totalCount,
                        loader: false,
                        searchLoader: false
                    })
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false, searchLoader: true })
                    toast.error(response.error.errorMessage)
                }
            })
    }

    openAddRolePopup = () => this.setState({ AddRolePopup: !this.state.AddRolePopup });

    closeModal = () => this.setState({ AddRolePopup: false, accessiblePaths: [], roleName: '', deleteRolePopup: false, editRolePopup: false, tempRoleId: '', viewRolePopup: false, titleErrorMsg: '' });

    handleChange = e => {
        let { name, value } = e.target
        this.setState({ [name]: value, titleErrorMsg: '' })
    }


    handleSubmitRole = (e) => {
        e.preventDefault();
        let { roleName, accessiblePaths } = this.state;
        let params = {
            roleName,
            accessiblePaths
        }
        if (!roleName) {
            this.setState({ titleErrorMsg: 'Enter the role name.' })
        } else if (!accessiblePaths || (accessiblePaths && !accessiblePaths.length)) {
            this.setState({ titleErrorMsg: 'Select atleast one accesible path' })
        } else {
            this.addRoleApi(params)
        }
    }

    addRoleApi = (params) => {
        addRole(params)
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    this.getRolesList()
                    this.setState({ loader: false })
                    toast.success(response.responseData.message)
                    this.closeModal()
                } else if (response.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(response.error.errorMessage)
                }
            })
    }


    openDeleteRolePopup = (data, role) => this.setState({ deleteRolePopup: !this.state.deleteRolePopup, tempRoleId: role._id });


    deleteRole = (e) => {
        e.preventDefault();
        const { tempRoleId } = this.state
        let paramsObj = {
            roleId: tempRoleId
        }
        deleteRole(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getRolesList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    openViewRolePopup = (data, role) => {
        this.setState({
            viewRolePopup: true,
            roleName: role.roleName,
            accessiblePaths: role.accessiblePaths,
        })
    }

    openEditRolePopup = (data, role) => {
        role.accessiblePaths && role.accessiblePaths.map(path => {
            path.label = path.title
            path.value = path.title
        })
        this.setState({
            editRolePopup: true,
            tempRoleId: role._id,
            roleName: role.roleName,
            accessiblePaths: role.accessiblePaths,
        })
    }


    editRoleSubmit = (e) => {
        e.preventDefault();
        const { roleName, accessiblePaths, tempRoleId } = this.state
        let paramsObj = {
            roleName, accessiblePaths, roleId: tempRoleId
        }
        if (!roleName) {
            this.setState({ titleErrorMsg: "Role name can't be empty!" })
        } else if (!accessiblePaths || (accessiblePaths && !accessiblePaths.length)) {
            this.setState({ titleErrorMsg: "Accessible paths can't be empty" })
        } else {
            this.setState({ titleErrorMsg: "" })
            this.editRoleApi(paramsObj)
        }
    }

    editRoleApi = (params) => {
        editRole(params)
            .then(res => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getRolesList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }


    handleSort = e => this.setState({ sortField: e, sortType: this.state.sortType === 1 ? 2 : 1 }, () => this.getRolesList());

    handleSearch = e => this.setState({ search: e.target.value, searchLoader: true }, () => this.getRolesList());

    handlePageChange = (pageNumber) => this.setState({ pageNo: pageNumber }, () => { this.getRolesList() });


    render() {
        const {
            loader,
            rolesList,
            search,
            AddRolePopup,
            titleErrorMsg,
            deleteRolePopup,
            viewRolePopup,
            pageNo,
            editRolePopup,
            count,
            totalRoles,
            roleName,
            accessiblePaths,
            searchLoader
        } = this.state;
        return (
            <HOC>
                <div className="body-container-wrapper">
                    <div className="body-container">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Roles</li>
                        </ol>


                        {loader
                            ? <div className="loader_wrapper"><LoaderSvg /></div>
                            : null}

                        <div className="users_header">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading margin-bottom-10px mt-1">Roles</h4>
                                    </div>
                                    <div className="col-md-3 text-right cm_search position-relative">
                                        {searchLoader ?
                                            <SearchSpinner type="text-info" />
                                            : null}
                                        <input
                                            type="text"
                                            className="form-control m-b-md-15"
                                            placeholder="Search by role name"
                                            onChange={this.handleSearch}
                                            value={search}
                                        />
                                        <i className="fa fa-search" ></i>
                                    </div>
                                    <div className="col-md-3 text-right">
                                        <button className="btn btn-primary btn-block" onClick={this.openAddRolePopup}>Add New</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center">
                                <thead>
                                    <tr>
                                        <th scope="col">Sr. No</th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('roleName')}>Role Name &nbsp;  <i className={`fa fa-sort-alpha-asc`} />
                                        </th>
                                        <th scope="col" className="filter_text" onClick={() => this.handleSort('created')}>Created &nbsp;  <i className={`fa fa-sort-numeric-asc`} />
                                        </th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>


                                <tbody>
                                    {
                                        rolesList.map((role, i) => {
                                            return (
                                                <tr key={role._id}>
                                                    <td>{((i + 1) + ((pageNo - 1) * count))}</td>

                                                    <td><span className="cm_table_three_dots">{role.roleName}</span></td>

                                                    <td className="cm_no_wrap">{dateFormat(role.created, "d mmmm yyyy")}</td>

                                                    <td className="cm_no_wrap">
                                                        <button className="btn btn-primary btn-sm" onClick={(data) => this.openViewRolePopup(data, role)}>View Permissions</button>

                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-dark btn-sm" onClick={(data) => this.openEditRolePopup(data, role)} >Edit</button>

                                                        &nbsp;&nbsp;
                                                        <button className="btn btn-danger btn-sm" onClick={(data) => this.openDeleteRolePopup(data, role)}>Delete</button>
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>

                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalRoles}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!rolesList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}

                        </div>

                    </div>
                </div>



                <Popup
                    open={AddRolePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Role</h3>
                        <form onSubmit={this.handleSubmitRole}>
                            <div className="form-group">
                                <label>Role Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="roleName"
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Accessible Paths</label>
                                <Select
                                    isMulti
                                    isSearchable
                                    options={Menus}
                                    placeholder="Select acessible paths"
                                    onChange={(val) => this.handlePaths(val)}
                                />
                            </div>
                            {titleErrorMsg && <h6 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h6>}

                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Add</button>
                            </div>
                        </form>
                    </div>
                </Popup>



                <Popup
                    open={editRolePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal" >
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Edit Role</h3>
                        <form onSubmit={this.editRoleSubmit}>
                            <div className="form-group">
                                <label>Role Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="roleName"
                                    value={roleName}
                                    onChange={this.handleChange}
                                />
                            </div>

                            <div className="form-group">
                                <label>Accessible Paths</label>
                                <Select 
                                    isMulti
                                    isSearchable
                                    options={Menus}
                                    value={accessiblePaths}
                                    placeholder="Select acessible paths"
                                    onChange={(val) => this.handlePaths(val)}
                                />
                            </div>
                            {titleErrorMsg && <h6 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h6>}
                            <div className="text-center">
                                <button className="btn btn-primary" type="submit">Update</button>
                            </div>
                        </form>
                    </div>
                </Popup>


                <Popup
                    open={deleteRolePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4">Are you sure, you want to delete this Role?</h5>

                        {titleErrorMsg && <h6 className="text-center mb-4 mt-5 error_message">{titleErrorMsg}</h6>}

                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteRole}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>


                <Popup
                    open={viewRolePopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}
                >
                    <div className="cm_modal">
                        <div className="card card_style">
                            <div className="card-body text-center modal-custom-max-bar-margin">
                                <h3>{roleName}</h3>
                                Accessible Paths:
                                <h4 className="">
                                    {accessiblePaths && accessiblePaths.length && accessiblePaths.map(path => {
                                        return (
                                            <span className="badge badge-info ml-2 mt-2">{path.title}</span>
                                        )
                                    })}
                                </h4>
                            </div>
                        </div>
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                    </div>
                </Popup>

            </HOC>
        )
    }
}



export default Roles;
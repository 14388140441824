import setting from './settings'

export default (() => {
  return {
    'AUTH': 'Basic Zml0c3Rhcl9hZG1pbjphZG1pbkBmaXRzdGFy',
    'ADMIN_LOGIN': setting.api.url + "v1/admin/loginV1",
    'VERIFY_OTP': setting.api.url + "v1/admin/verifyOtpV1",
    'RESEND_OTP': setting.api.url + "v1/admin/resendOtpV1",
    'ADMIN_EXIST': setting.api.url + "v1/admin/isAdminExist",
    'RESET_PASSWORD': setting.api.url + "v1/admin/resetPasswordV1",
    'ADMIN_LOGOUT': setting.api.url + "v1/user/logout",
    'GET_ADMIN_PROFILE': setting.api.url + "v1/admin/getAdminProfile",
    'GET_ALL_ADMINS': setting.api.url + "v1/admin/getAllSubAdmins",
    'ADD_SUB_ADMIN': setting.api.url + "v1/admin/addSubAdmin",
    'UPDATE_USER_COUPON': setting.api.url + "v1/admin/updateUserCoupon",
    'ADD_PRODUCT_PURCHASED': setting.api.url + "v1/admin/addProductPurchased",
    'EDIT_SUB_ADMIN': setting.api.url + "v1/admin/editSubAdmin",

    'CHECK_SESSION': setting.api.url + "v1/admin/checkSession",
    'GET_ALL_DATA_COUNT': setting.api.url + "v1/admin/getAllCounts",
    'GET_APP_USERS': setting.api.url + "v1/admin/getAppUsers",
    'GET_USER': setting.api.url + "v1/admin/viewUser",
    'USER_CHANGE_STATUS': setting.api.url + "v1/admin/changeStatus",
    'GET_COUPONS': setting.api.url + "v1/coupon/getAllCoupons",
    'GENERATE_COUPON': setting.api.url + "v1/coupon/generateCoupon",
    'GET_TOTAL_COUPONS': setting.api.url + "v1/coupon/getCouponCount",
    'DELETE_COUPON': setting.api.url + "v1/coupon/deleteCoupon",
    'GET_TOTAL_USERS': setting.api.url + "v1/admin/getUsersCount",
    'GET_TOTAL_DEVICES': setting.api.url + "v1/device/getDeviceCount",
    'SEND_COUPON': setting.api.url + "v1/coupon/sendCoupon",
    'ADMIN_FORGET_PASSWORD': setting.api.url + "v1/admin/forgotPasswordV1",
    // 'GET_EXPORT_USERS': setting.api.url + "v1/admin/getAppUsersExport",

    'ADD_DIETICIAN': setting.api.url + "v1/admin/addDietician",
    'GET_ALL_DIETICIANS': setting.api.url + "v1/admin/getAllDieticians",
    'ASSIGN_DIETICIAN': setting.api.url + "v1/admin/assignDietician",
    'CHANGE_DIETICIAN_STATUS': setting.api.url + "v1/admin/changeDieticianStatus",
    'GET_USER_METRICS': setting.api.url + "v1/admin/getUserMetricsExport",

    'ADD_TRAINER': setting.api.url + "v1/admin/addFitnessTrainer",
    'GET_ALL_TRAINERS': setting.api.url + "v1/admin/getAllTrainers",
    'ASSIGN_TRAINER': setting.api.url + "v1/admin/assignTrainer",
    'EDIT_TRAINER_BY_ADMIN': setting.api.url + "v2/admin/editProfileByAdmin",
    'GET_EXPERTS_BY_SERVICE_ID': setting.api.url + "v1/admin/getExpertsByServiceId",
    'EDIT_USER_PROFILE': setting.api.url + "v1/admin/editUserProfile",
    'UPLOAD_TRAINER_IMG': setting.api.url + "v1/admin/uploadTrainerImages",
    'UPLOAD_TRAINER_VIDEO': setting.api.url + "v1/admin/uploadTrainerVideo",

    'GET_DIETICIAN_RATINGS': setting.api.url + "v1/dieticianRating/getDieticianRatings",

    'GET_EXERCISE_CATEGORIES': setting.api.url + "v1/exerciseCategory/getExerciseCategories",
    'ADD_EXERCISE_CATEGORIES': setting.api.url + "v1/exerciseCategory/addExerciseCategory",
    'EDIT_EXERCISE_CATEGORY': setting.api.url + "v1/exerciseCategory/editExerciseCategory",
    'DELETE_EXERCISE_CATEGORY': setting.api.url + "v1/exerciseCategory/deleteExerciseCategory",

    'GET_FITNESS_EXERCISES': setting.api.url + "v1/fitnessExercise/getFitnessExercises",
    'ADD_FITNESS_EXERCISES': setting.api.url + "v1/fitnessExercise/addFitnessExercise",
    'EDIT_FITNESS_EXERCISE': setting.api.url + "v1/fitnessExercise/editFitnessExercise",
    'DELETE_FITNESS_EXERCISE': setting.api.url + "v1/fitnessExercise/deleteFitnessExercise",

    'USER_EMAIL_EXISTS': setting.api.url + "v1/user/emailExists",
    'USER_PHONE_EXISTS': setting.api.url + "v1/user/phoneExists",
    'USER_SEND_OTP': setting.api.url + "v1/user/sendOtp",
    'USER_VERIFY_OTP': setting.api.url + "v1/user/verifyOtp",
    'USER_LOGIN': setting.api.url + "v1/user/login",
    'USER_REGISTER': setting.api.url + "v1/user/`register`",
    'USER_UPDATE_CARD_DETAILS': setting.api.url + "v1/user/updateCardDetails",
    'USER_LOGOUT': setting.api.url + "v1/user/logout",
    'USER_SOCIAL_LOGIN': setting.api.url + "v1/user/socialLogin",
    'USER_FORGET_PASSWORD': setting.api.url + "v1/user/forgetPassword",
    'USER_CHANGE_PASSWORD': setting.api.url + "v1/admin/changePassword",
    'USER_RESET': setting.api.url + "v1/user/reset",
    'USER_GET_PROFILE': setting.api.url + "v1/user/getProfile",
    'USER_EDIT_PROFILE': setting.api.url + "v1/user/editProfile",
    'USER_DELETE_PROFILE': setting.api.url + "v1/user/deleteProfile",
    'USER_SYNCED_DEVICES': setting.api.url + "v1/user/syncedDevices",
    'USER_STATS': setting.api.url + "v1/user/userStats",
    'GET_SELECTED_TIME_SLOTS': setting.api.url + "v1/user/getSelectedTimeSlots",
    'ASSIGN_POLICIES': setting.api.url + "v1/user/assignPolicies",
    'ASSIGN_DIETICIAN_TO_MULTIPLE_USERS': setting.api.url + "v1/user/assignDieticianToMultipleUsers",
    'ASSIGN_COUPON_TO_MULTIPLE_USERS': setting.api.url + "v1/user/assignCouponToMultipleUsers",
    'SEND_OTP': setting.api.url + "v1/user/sendOtpV2",
    'GET_CONFIGS': setting.api.url + "v1/user/getConfigs",
    "GET_LEADERBOARD_CONFIG": setting.api.url + "v1/fitplusbot/getLeaderboardConfig",

    'GET_ALL_COLLECTIONS': setting.api.url + "v1/onDemandCollection/searchOnDemandCollections",
    'UPLOAD_COLLECTION_IMAGE': setting.api.url + "v1/onDemandCollection/uploadCollectionImage",
    'ADD_COLLECTION': setting.api.url + "v1/onDemandCollection/addOnDemandCollection",
    'UPDATE_COLLECTION': setting.api.url + "v1/onDemandCollection/updateOnDemandCollection",
    'DELETE_COLLECTION': setting.api.url + "v1/onDemandCollection/deleteOnDemandCollection",

    'GET_USER_TARGET': setting.api.url + "v1/target/getUserTargetByAdmin",
    'GET_USER_LOGS': setting.api.url + "v1/userLogs/getUserNotesByAdmin",
    'GET_USER_WITH_TRAINER_LOGS': setting.api.url + "v1/userLogs/getUserWithTrainerNotes",
    'GET_USER_MESSAGES': setting.api.url + "v1/messages/getMessages",
    'GET_USER_MESSAGES_ADMIN': setting.api.url + "v1/messages/getMessagesByAdmin",
    'GET_USER_DAILY_DIET' : setting.api.url + "v1/diet/userDietByAdmin",


    'DEVICE_ADD_DEVICE': setting.api.url + "v1/device/addDevice",
    'DEVICE_UPDATE_DEVICE': setting.api.url + "v1/device/updateDevice",
    'GET_DEVICE': setting.api.url + "v1/device/getDeviceDetails",
    'DEVICE_DELETE_DEVICE': setting.api.url + "v1/device/deleteDevice",
    'DEVICE_GET_DEVICE': setting.api.url + "v1/device/getAllDevices",

    'GET_PRODUCTS': setting.api.url + "v1/products/getAdminProducts",
    'DELETE_PRODUCT': setting.api.url + "v1/products/deleteProduct",
    'GET_PRODUCT': setting.api.url + "v1/products/getProduct",
    'GET_PRODUCTS_COUNT': setting.api.url + "v1/products/getTotalProductsCount",
    'ADD_PRODUCT': setting.api.url + "v2/product/addProduct",
    'EDIT_PRODUCT': setting.api.url + "v2/product/editProduct",
    'ADD_KEY_FEATURE': setting.api.url + "v2/product/addKeyFeatures",
    'REMOVE_KEY_FEATURE': setting.api.url + "v2/product/removeKeyFeatures",


    'GET_ALL_FOODS': setting.api.url + "v1/food/getAllFoods",
    'ADD_FOOD': setting.api.url + "v1/food/addFood",
    'DELETE_FOOD': setting.api.url + "v1/food/deleteFood",
    'EDIT_FOOD': setting.api.url + "v1/food/editFood",
    'UPLOAD_FOOD_FROM_EXCEL': setting.api.url + "v1/food/uploadFoodFromExcel",


    'GET_FOOD_CATEGORIES': setting.api.url + "v1/foodCategory/getFoodCategories",
    'ADD_FOOD_CATEGORY': setting.api.url + "v1/foodCategory/addFoodCategory",
    'EDIT_FOOD_CATEGORY': setting.api.url + "v1/foodCategory/editFoodCategory",
    'DELETE_FOOD_CATEGORY': setting.api.url + "v1/foodCategory/deleteFoodCategory",

    'GET_FOOD_REQUESTS': setting.api.url + "v1/foodRequest/getFoodRequests",
    'CHANGE_REQ_STATUS': setting.api.url + "v1/foodRequest/changeRequestStatus",

    'GET_ALL_HELP_VIDEOS': setting.api.url + "v1/helpVideos/getAllHelpVideos",
    'ADD_HELP_VIDEO': setting.api.url + "v1/helpVideos/addHelpVideo",
    'DELETE_HELP_VIDEO': setting.api.url + "v1/helpVideos/deleteHelpVideo",
    'EDIT_HELP_VIDEO': setting.api.url + "v1/helpVideos/editHelpVideo",

    'GET_HELP_VIDEO_CATEGORIES': setting.api.url + "v1/helpVideoCategory/getHelpVideoCategories",
    'GET_HELP_VIDEO_CATEGORIES_LABEL': setting.api.url + "v1/helpVideoCategory/getHelpVideoCategoriesLabels",
    'ADD_HELP_VIDEO_CATEGORY': setting.api.url + "v1/helpVideoCategory/addHelpVideoCategory",
    'EDIT_HELP_VIDEO_CATEGORY': setting.api.url + "v1/helpVideoCategory/editHelpVideoCategory",
    'DELETE_HELP_VIDEO_CATEGORY': setting.api.url + "v1/helpVideoCategory/deleteHelpVideoCategory",

    'GET_ALL_QUESTIONS': setting.api.url + "v1/questions/getAllQuestionsViaAdmin",
    'CREATE_QUESTION': setting.api.url + "v1/questions/createQuestion",
    'CHANGE_QUESTION_STATUS': setting.api.url + "v1/questions/changeQuestionStatus",
    'GET_USER_ANSWER_BY_ADMIN': setting.api.url + "v1/questions/getUserAnswersAdmin",

    'VERSION_ADD_APP_VERSION': setting.api.url + "v1/version/addAppVersion",
    'VERSION_ADD_API_VERSION': setting.api.url + "v1/version/addApiVersion",
    'VERSION_DELETE_APP_VERSION': setting.api.url + "v1/version/deleteAppVersion",
    'VERSION_DELETE_API_VERSION': setting.api.url + "v1/version/deleteApiVersion",
    'VERSION_GET_CONFIG_INFO': setting.api.url + "v1/version/getConfigInfo",


    'DIET_TEMPELATE_LABELS': setting.api.url + "v1/dietPlanTempelate/getTempelatesByAdmin",
    'GET_DIET_PLAN_TEMPELATE': setting.api.url + "v1/dietPlanTempelate/getDietPlanTemplate",
    'EDIT_DIET_PLAN_TEMPLATE': setting.api.url + "v1/dietPlanTempelate/editDietPlanTemplate",
    'DELETE_DIET_PLAN_TEMPLATE': setting.api.url + "v1/dietPlanTempelate/deleteTemplateById",
    'GET_ALL_HISTORY_BY_ADMIN': setting.api.url + "v1/dietPlanHistory/getDietPlanHistoryByAdmin",
    'GET_SINGLE_PLAN_HISTORY_BY_ADMIN': setting.api.url + "v1/dietPlanHistory/getSinglePlanHistory",



    'GET_FITNESS_PLAN_BY_ADMIN': setting.api.url + "v1/fitnessPlan/getUserFitnessplanAdmin",
    'FITNESS_PLAN_TEMPLATE_BY_ADMIN': setting.api.url + "v1/fitnessPlanTempelate/getFitnessTemplatesByAdmin",
    'GET_FITNESS_PLAN_TEMPLATE': setting.api.url + "v1/fitnessPlanTempelate/getFitnessTempelate",
    'EDIT_FITNESS_PLAN_TEMPLATE': setting.api.url + "v1/fitnessPlanTempelate/editDietPlanTemplate",
    'DELETE_FITNESS_PLAN_TEMPLATE': setting.api.url + "v1/fitnessPlanTempelate/deleteTemplateById",


    'GET_USER_DIET_PLAN': setting.api.url + "v1/dietPlan/getDietPlanViaAdmin",
    'EDIT_DIET_PLAN': setting.api.url + "v1/dietPlan/editDietPlan",

    'GET_ROLES': setting.api.url + "v1/roles/getRoles",
    'ADD_ROLE': setting.api.url + "v1/roles/addRole",
    'EDIT_ROLE': setting.api.url + "v1/roles/editRole",
    'DELETE_ROLE': setting.api.url + "v1/roles/deleteRole",

    "ADD_COINS": setting.api.url + "v1/coins/addCoins",
    "EDIT_COINS_WEIGHTAGE": setting.api.url + "v1/coins/updateCoinsWeightage",
    "DELETE_COINS_WEIGHTAGE": setting.api.url + "v1/coins/deleteCoinsWeightage",
    "GET_COINS_WEIGHTAGE": setting.api.url + "v1/coins/getCoinsWeightage",

    "ADD_REWARD_PRODUCTS": setting.api.url + "v1/rewardProducts/addRewardProducts",
    "EDIT_REWARD_PRODUCTS": setting.api.url + "v1/rewardProducts/editRewardProducts",
    "DELETE_REWARD_PRODUCTS": setting.api.url + "v1/rewardProducts/deleteRewardProducts",
    "GET_REWARD_PRODUCTS": setting.api.url + "v1/rewardProducts/getRewardProducts",

    "GET_TRANSACTIONS": setting.api.url + "v1/rewardTransactions/getTransactions",

    "GET_TRANSACTION_DETAILS": setting.api.url + "v1/transactionDetails/getTransactionDetails",
    "UPDATE_DELIVERY_STATUS": setting.api.url + "v1/transactionDetails/updateDeliveryStatus",

    "GET_TRACKS": setting.api.url + "v1/outdoorTracks/getTracks",
    "ADD_TRACK": setting.api.url + "v1/outdoorTracks/addTrack",
    "UPDATE_TRACK": setting.api.url + "v1/outdoorTracks/updateTrack",
    "DELETE_TRACK": setting.api.url + "v1/outdoorTracks/deleteTrack",
    "UPLOAD_TRACKS_EXCEL": setting.api.url + "v1/outdoorTracks/uploadTracksViaExcel",

    "ADD_SERVICE": setting.api.url + "v1/services/addService",
    "GET_SERVICES": setting.api.url + "v1/services/getServices",
    "GET_SERVICES_V2": setting.api.url + "v2/services/getServices",
    "GET_SERVICES_FOR_SESSION": setting.api.url + "v1/services/getServicesForSession",
    "UPDATE_SERVICE": setting.api.url + "v1/services/updateService",
    "DELETE_SERVICE": setting.api.url + "v1/services/deleteService",


    "ADD_SERVICE_BANNER": setting.api.url + "v1/serviceBanners/addServiceBanner",
    "GET_SERVICE_BANNERS": setting.api.url + "v1/serviceBanners/getServiceBannersByAdmin",
    "UPDATE_SERVICE_BANNER": setting.api.url + "v1/serviceBanners/updateServiceBanner",
    "DELETE_SERVICE_BANNER": setting.api.url + "v1/serviceBanners/deleteServiceBanner",

    "GET_CALL_SCHEDULES": setting.api.url + "v1/callScheduling/getAllSessionsByAdmin",
    "CHANGE_CALL_REQUEST_STATUS": setting.api.url + "v1/callScheduling/changeRequestStatus",

    "GET_HOLIDAYS": setting.api.url + "v1/holidays/getHolidaysByAdmin",
    "ADD_HOLIDAY": setting.api.url + "v1/holidays/addHoliday",

    'GET_CUSTOMER_TYPES': setting.api.url + "v1/customerTypes/getCustomerTypesList",
    'ADD_CUSTOMER_TYPE': setting.api.url + "v1/customerTypes/addCustomerType",
    'UPDATE_CUSTOMER_TYPE': setting.api.url + "v1/customerTypes/updateCustomerType",
    'DELETE_CUSTOMER_TYPE': setting.api.url + "v1/customerTypes/deleteCustomerType",

    'GET_CUSTOMER_SERVICE_TYPES': setting.api.url + "v1/customerServiceTypes/getCustomerServiceTypesList",
    'ADD_CUSTOMER_SERVICE_TYPE': setting.api.url + "v1/customerServiceTypes/addCustomerServiceType",
    'UPDATE_CUSTOMER_SERVICE_TYPE': setting.api.url + "v1/customerServiceTypes/updateCustomerServiceType",
    'DELETE_CUSTOMER_SERVICE_TYPE': setting.api.url + "v1/customerServiceTypes/deleteCustomerServiceType",


    'GET_SERVICE_POLICY': setting.api.url + "v1/servicePolicy/getServicePolicyList",
    'ADD_SERVICE_POLICY': setting.api.url + "v1/servicePolicy/addServicePolicy",
    'UPDATE_SERVICE_POLICY': setting.api.url + "v1/servicePolicy/updateServicePolicy",
    'DELETE_SERVICE_POLICY': setting.api.url + "v1/servicePolicy/deleteServicePolicy",
    'GET_ALL_SERVICE_POLICIES': setting.api.url + "v1/servicePolicy/getAllServicePolicies",
    'GET_SERVICE_POLICY_V2': setting.api.url + "v1/servicePolicy/getServicePolicyV2",
    'GET_SERVICE_POLICY_BY_ADMIN': setting.api.url + "v1/servicePolicy/getServicePolicyByAdmin",

    "GET_RIDERS_LIST": setting.api.url + "v1/fitpluslogistic/deliveryRider/getRidersList",
    "VERIFY_RIDER_BY_ADMIN": setting.api.url + "v1/fitpluslogistic/deliveryRider/verifyRiderByAdmin",
    "GET_RIDERS_LOCATION_LOGS": setting.api.url + "v1/fitpluslogistic/deliveryRider/getRidersLocationLogs",
    "DELETE_RIDER": setting.api.url + "v1/fitpluslogistic/deliveryRider/deleteRider",
    "EDIT_RIDER": setting.api.url + "v1/fitpluslogistic/deliveryRider/editRider",


    'GET_STATIONS_BY_ADMIN': setting.api.url + "v1/fitpluslogistic/stations/getStationsListByAdmin",
    'ADD_STATION': setting.api.url + "v1/fitpluslogistic/stations/addStation",
    'UPDATE_STATION': setting.api.url + "v1/fitpluslogistic/stations/updateStation",
    'DELETE_STATION': setting.api.url + "v1/fitpluslogistic/stations/deleteStation",

    'GET_FREE_SESSIONS_BY_ADMIN': setting.api.url + "v1/videoSessions/getFreeSessionsByAdmin",
    'CREATE_FREE_SESSION_BY_ADMIN': setting.api.url + "v1/videoSessions/createFreeSessionByAdmin",
    'DELETE_FREE_SESSIONS_BY_ADMIN': setting.api.url + "v1/videoSessions/deleteFreeSessionByAdmin",

    'GET_LEAVES_LIST': setting.api.url + "v1/leaves/getLeavesListByAdmin",
    'CHANGE_LEAVE_STATUS': setting.api.url + "v1/leaves/changeLeaveStatus",

    /******************One Fitplus services******************/

    "ADD_GUARANTEED_PLAN_FEATURE": setting.api.url + "v2/guaranteedPlanFeature/addGuaranteedPlanFeature",
    "EDIT_GUARANTEED_PLAN_FEATURE": setting.api.url + "v2/guaranteedPlanFeature/editGuaranteedPlanFeature",
    "GET_GUARANTEED_PLAN_FEATURES": setting.api.url + "v2/guaranteedPlanFeature/getGuaranteedPlanFeatureList",
    "DELETE_GUARANTEED_PLAN_FEATURE": setting.api.url + "v2/guaranteedPlanFeature/deleteGuaranteedPlanFeature",
    "UPLOAD_FEATURE_IMAGE": setting.api.url + "v2/guaranteedPlanFeature/uploadFeatureImage",

    'WATER_INTAKE_GRAPH': setting.api.url + "v2/user/waterInTakeGraphByAdmin",
    'STEPS_GRAPH': setting.api.url + "v2/user/stepsGraphByAdmin",
    'HEART_RATE_GRAPH': setting.api.url + "v2/user/heartRateByAdmin",
    'CALORIES_GRAPH': setting.api.url + "v2/user/caloriesByAdmin",
    'SLEEP_GRAPH': setting.api.url + "v2/user/sleepActivityByAdmin",
    'WEIGHT_GRAPH': setting.api.url + "v2/user/weightGraphByAdmin",
    'UPDATE_KYC': setting.api.url + "v2/user/updateKyc",


    'ADD_SUCCESS_STORIES': setting.api.url + "v2/achieveResult/addSuccessStories",
    'EDIT_SUCCESS_STORY': setting.api.url + "v2/achieveResult/editSuccessStories",
    'GET_SUCCESS_STORIES': setting.api.url + "v2/achieveResult/getSuccessStoriesByAdmin",
    'DELETE_SUCCESS_STORY': setting.api.url + "v2/achieveResult/deleteSuccessStories",

    'ADD_TESTIMONIAL': setting.api.url + "v2/testimonial/addTestimonial",
    'EDIT_TESTIMONIAL': setting.api.url + "v2/testimonial/editTestimonial",
    'GET_TESTIMONIAL': setting.api.url + "v2/testimonial/getTestimonialByAdmin",
    'DELETE_TESTIMONIAL': setting.api.url + "v2/testimonial/deleteTestimonial",


    'ADD_BLOG': setting.api.url + "v2/blog/addBlog",
    'EDIT_BLOG': setting.api.url + "v2/blog/editBlog",
    'GET_BLOGS_LIST': setting.api.url + "v2/blog/getBlogListByAdmin",
    'DELETE_BLOG': setting.api.url + "v2/blog/deleteParticularBlog",
    'GET_PARTICULAR_BLOG': setting.api.url + "v2/blog/getParticularBlog",

    //Order Managment 
    'SUBSCRIPTIONS_LIST': setting.api.url + "v2/subscription/subscriptionListByAdmin",
    'EDIT_SUBSCRIPTION_END_DATE': setting.api.url + "v2/subscription/editEndDate",
    'CANCEL_SUBSCRIPTION': setting.api.url + "v2/subscription/cancelSubscription",
    'BULK_UPLOAD_ORDERS':setting.api.url + "v3/admin/orders/import",
    'DOWNLOAD_ORDERS': setting.api.url + "v3/admin/orders/export",
    'GET_ORDERS': setting.api.url + "v3/admin/orders",
    'EDIT_ORDER': setting.api.url + "v3/admin/orders",

    //Review
    'GET_REVIEWS_LIST': setting.api.url + "v2/rating/getReviewsListByAdmin",
    'APPROVE_REVIEW': setting.api.url + "v2/rating/approveReviewByAdmin",
    'EDIT_REVIEWS_BY_ADMIN': setting.api.url + "v2/rating/editReviewsByAdmin",
    'DELETE_REVIEW': setting.api.url + "v2/rating/deleteReviews",

    'EDIT_WHY_CHOOSE_US': setting.api.url + "v2/whyChooseUs/editWhyChooseUs",
    'GET_WHY_CHOOSE_US': setting.api.url + "v2/whyChooseUs/getWhyChooseUs",

    'ADD_QUESTION': setting.api.url + "v2/questionarie/addQuestionsByAdmin",
    'EDIT_QUESTION': setting.api.url + "v2/questionarie/editQuestionsByAdmin",
    'APPROVE_QUESTION': setting.api.url + "v2/questionarie/approveQuestionsByAdmin",
    'GET_QUESTIONS_LIST': setting.api.url + "v2/questionarie/getQuestionsListByAdmin",
    'DELETE_QUESTION': setting.api.url + "v2/questionarie/deleteQuestions",

    'GET_LOCATIONS': setting.api.url + "v2/location/getLocation",
    'ADD_LOCATION': setting.api.url + "v2/location/addLocation",

    /******************One Fitplus services End******************/



    //******************Fitplus Go service App*************// 
    'GET_ADMINSERVICE': setting.api.url + "v1/productServiceRequest/getProductServiceRequest",
    'ADD_REQUESTS_FROM_EXCEL': setting.api.url + "v1/productServiceRequest/addRequestsFromExcel",
    'UPDATE_REQUESTS_FROM_EXCEL': setting.api.url + "v1/productServiceRequest/updateServiceRequestFromExcel",
    'FIND_EXISTING_ORDERID_FROM_EXCEL': setting.api.url + "v1/productServiceRequest/findExistingOrderIdFromExcel",
    'ADD_MULTI_REQUESTS_BY_ADMIN': setting.api.url + "v1/productServiceRequest/addMultipleRequestsByAdmin",
    'ADD_REQUEST_BY_ADMIN': setting.api.url + "v1/productServiceRequest/addRequestByAdmin",
    'UPDATE_REQUEST_BY_ADMIN': setting.api.url + "v1/productServiceRequest/updateServiceRequest",
    'DELETE_REQUEST': setting.api.url + "v1/productServiceRequest/deleteRequest",
    'ASSIGN_REQ_TO_VENDOR': setting.api.url + "v1/productServiceRequest/assignRequestToVendor",
    'CHANGE_REQUEST_STATUS': setting.api.url + "v1/productServiceRequest/updateRequestStatusByAdmin",
    'MARK_REQUEST_SELFDONE': setting.api.url + "v1/productServiceRequest/markRequestSelfDone",
    'EXPORT_SERVICE_REQUEST': setting.api.url + "v1/productServiceRequest/exportGenericServiceSheet",
    'GENERATE_TEMPLATE': setting.api.url + "v1/productServiceRequest/generateTemplate",
    'ALLOCATION_SERVICE_IN_BULK': setting.api.url + "v1/productServiceRequest/allocationServiceinBulk",
  
    
    // pincode's for the dashboard
    'GET_PINCODE_LIST': `${setting.api.url}v1/serviceablePincodes/getPincodesList`,
    'BULK_UPLOAD_PINCODES': `${setting.api.url}v1/serviceablePincodes/bulkUploadPincodes`,
    'ADD_PINCODE': `${setting.api.url}v1/serviceablePincodes/addPincode`,
    'EDIT_PINCODE': `${setting.api.url}v1/serviceablePincodes/editPincode`,
    'REMOVE_PINCODE': `${setting.api.url}v1/serviceablePincodes/removePincode`,

    // these are new API's optimized earlier would be removed when all the funcitionality would be given a go ahead

    'GET_STATE_LIST': `${setting.api.url}v1/serviceablePincodes/getServicableStates`,
    'GET_CITYS_LIST': `${setting.api.url}v1/serviceablePincodes/getServicableCitys`,
    'GET_PINCODE_VENDOR_ID': `${setting.api.url}v1/serviceablePincodes/getServiceablePincodesByAdminForVendorId`,

    //Deliverable Pincode
    'BULK_PINCODE_IMPORT': setting.api.url + "v3/admin/deliverable-pincode/import",
    'EXPORT_PINCODE_LIST': setting.api.url + "v3/admin/deliverable-pincode/export",
    'GET_DELIVERABLE_PINCODE': setting.api.url + "v3/admin/deliverable-pincode",
    'GET_WAREHOUSE': setting.api.url + "v3/admin/warehouses",
    'POST_DELIVERABLE_PINCODE': setting.api.url + "v3/admin/deliverable-pincode",
    'PUT_DELIVERABLE_PINCODE': setting.api.url + "v3/admin/deliverable-pincode",
    'GET_DETAIL_DELIVERABLE_PINCODE': setting.api.url + "v3/admin/deliverable-pincode",



    





    // vendor's addition for dashboard
    'ADD_VENDOR': setting.api.url + "v1/admin/addVendor",
    'GET_ALL_VENDORS': setting.api.url + "v1/admin/getAllVendors",
    'REMOVE_VENDOR': setting.api.url + "v1/admin/removeVendor",
    'UPDATE_VENDOR': setting.api.url + "v1/admin/editVendor",


    //Job management for service requests
    'GET_PRODUCTJOB_LIST': setting.api.url + "v1/job/getAllJobs",
    'ADD_PRODUCTJOB': setting.api.url + "v1/job/addJob",
    'DELETE_PRODUCTJOB': setting.api.url + "v1/job/deleteJob",
    'GET_PRODUCTJOB': setting.api.url + "v1/job/getJob",
    'EDIT_PRODUCTJOB': setting.api.url + "v1/job/editJob",
    'ASSIGN_PRODUCTJOBTOASSOCIATE': setting.api.url + "v1/job/assignMultipleJobsToAssociateEngineer",
    'CHANGE_PRODUCTJOBSTATUS': setting.api.url + "v1/job/updateJobStatus",
    'MARK_PRODUCTJOB_URGENT': setting.api.url + "v1/job/markJobUrgent",
    'EXPORT_PRODUCT_JOB_REQUEST': setting.api.url + "v1/job/exportGenericProductSheet",
    'UPDATE_PRODUCTJOB_FROM_EXCELSHEET': setting.api.url + "v1/job/updateFromExcel",
    // associate engineer for dashboard

    'GET_ALL_ASSOCIATE_ENGINEER': setting.api.url + "v1/admin/getAllEngineers",
    'REMOVE_ENGINEER': setting.api.url + "v1/admin/removeEngineer",
    'ADD_ENGINEER': setting.api.url + "v1/admin/addAssociate",
    'EDIT_ENGINEER': setting.api.url + "v1/admin/editEngineer",

    // Product Service Incident
    "GET_PRODUCTSERVICEINCIDENT_LIST": setting.api.url + "v1/productServiceIncidents/getProductServicIncidents",
    "ADD_NOTE_TO_REQUEST": setting.api.url + "v1/productServiceRequest/addNoteForServiceRequest",


    'CHANGE_STATUS_REWARD': setting.api.url + "v1/rewardTransactions/changeStatus",

    'ADD_STREAM': setting.api.url + "v1/broadCastStream/addBroadCastStream",
    'GET_STREAM_LIST': setting.api.url + "v1/broadCastStream/getBroadcastList",
    'CHANGE_STATUS_STREAM': setting.api.url + "v1/broadCastStream/changeStatus",
    'DELETE_STREAM': setting.api.url + "v1/broadCastStream/deleteBroadCastStream",
    'UPDATE_BROAD_CART_API': setting.api.url + "v1/broadCastStream/updateBroadCastStream",
    'DOWNLOAD_STREAMLIST': setting.api.url + "v1/broadCastStream/download/streamList",
    'ADD_UPDATE_NOTES': setting.api.url + "v1/broadCastStream/addOrUpdateLiveSessionNotes",

    // CRM Manager Crud 
    'ADD_CRM_AGENT': setting.api.url + "v1/admin/addCrmManager",
    'GET_CRM_AGENT': setting.api.url + "v1/admin/getCrmManagers",
    'ASSIGN_REQ_TO_AGENT': setting.api.url + "v1/productServiceRequest/assignRequestToAgent",

    // Admin Time Slot
    'GET_ADMIN_TIME_SLOT_LIST' : setting.api.url + "v1/adminTimeSlots/getAllTimeSlots",
    'UPDATE_ADMIN_TIME_SLOT' : setting.api.url + "v2/adminTimeSlots/updateTimeSlot",
    'DELETE_ADMIN_TIME_SLOT' : setting.api.url + "v2/adminTimeSlots/deleteTimeSlot",

    'GET_USER_TIME_SLOT_BY_ADMIN' : setting.api.url + "v2/admin/getUserTimeSlotsByAdmin",
    'GET_STREAM_DETAILS': setting.api.url + "v1/streamingAttendee/getLiveUsersList",
    "GET_LIVE_TRAINER_REVIEW_INFO": setting.api.url + "v1/streamingAttendee/getReviewsList",
    "DOWNLOAD_USER_LIST":setting.api.url + "v1/streamingAttendee/exportRequestsToExcel",
    "DOWNLOAD_FILTERED_USER_LIST":setting.api.url + "v1/streamingAttendee/download/filteredUsers",

    "GET_DIET_PLAN_APPROVE_PENDING": setting.api.url + "v1/dietPlanApprovePending/getApprovePendingOfUser",
    "APPROVE_DIET_PLAN": setting.api.url + "v1/dietPlanApprovePending/approveDietPlan",
    "REJECT_DIET_PLAN": setting.api.url + "v1/dietPlanApprovePending/rejectDietPlan",   

    // Ads
    'GET_ALL_ADS' : setting.api.url + "v1/ads/getListsByAdmin",
    'DELETE_ADS' : setting.api.url + "v1/ads/remove",
    'CREATE_ADS' : setting.api.url + "v1/ads/create",
    'EDIT_ADS' : setting.api.url + "v1/ads/update",

    //
    
    'ADD_CAREER': setting.api.url + "v1/career/create",
    'UPDATE_CAREER': setting.api.url + "v1/career/update",
    'CAREER_LISTING': setting.api.url + "v1/career/getListsByAdmin",
    'CAREER_CONFIG': setting.api.url + "v1/career/getConfig",
    'UPDATE_CAREER_STATUS': setting.api.url + "v1/career/changeCareerStatus",



    // Emoji

    'ADD_EMOJI':setting.api.url +"v1/emoji/addEmoji",
    'GET_EMOJI':setting.api.url +"v1/emoji/getEmojisListByAdmin",
    'DELETE_EMOJI':setting.api.url+"v1/emoji/deleteEmoji",
    'EDIT_EMOJI':setting.api.url+"v1/emoji/updateEmoji",
    'GET_EMOJI_BY_PLATOFORM':setting.api.url +"v1/emoji/getEmojisList",
    // 'GET_EXPORT_USERS': setting.api.url + "v1/admin/getAppUsersExport",
    'GET_EXPORT_USERS': setting.api.url + "v1/exportRequest/save",

    // inventory's end points
    'INVENTRY': `${setting.api.url}v3/admin/inventory`,
    'BULK_UPLOAD_INVENTORY': `${setting.api.url}v3/admin/inventory/import`,
    'DOWNLOAD_INVENTORY_LIST': `${setting.api.url}v3/admin/inventory/export`,

    // product coupon end points
    'PRODUCT_COUPONS': `${setting.api.url}v3/admin/coupons`,
    'BULK_UPLOAD_PRODUCT_COUPONS': `${setting.api.url}v3/admin/coupons/import`,
    'DOWNLOAD_PRODUCT_COUPONS': `${setting.api.url}v3/admin/coupons/export`,
       
    //Order Feedback 
    'GET_ORDER_FEEDBACK': setting.api.url + "v3/admin/order/feedback",

    //Order History
  'GET_ORDER_HISTORY': setting.api.url + "v3/admin/order/history",
  'POST_ORDER_HISTORY': setting.api.url + "v3/admin/order/history",
  'UPDATE_ORDER_HISTORY': setting.api.url + "v3/admin/order/history",

  'ADD_NOTE': setting.api.url + "v1/notes/addNote",
  'GET_NOTE': setting.api.url + "v1/notes/getNotes",

  'GET_AVAILABLE_TIMESLOTS_OF_EXPERT': setting.api.url + "v2/user/getAvailableTimeSlotsOfExpert",
  'GET_TRAINERS_BY_AVAILABLE_SLOT_V2': setting.api.url + "v1/admin/getTrainersByAvailableSlotV2",
  'GET_USER_FREE_SESSION_LEFT': setting.api.url + "v1/admin/getUserFreeSessionsLeft",
  'GET_USER_PAYMENT_DUE_SESSIONS': setting.api.url + "v1/admin/getUserPaymentDueSession",
  'GET_STREAMING_TYPE_DISPLAY': setting.api.url + "v1/admin/getStreamingTypeDisplayConfig",
  'UPDATE_STREAMING_TYPE_DISPLAY': setting.api.url + "v1/admin/updateStreamingTypeDisplayConfig",
  'BOOK_TIME_SLOT_OF_EXPERT': setting.api.url + "v1/callScheduling/scheduleVideoSessionBYAdmin",

  }
})()

import React, { Fragment, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import {ReactComponent as Logo} from '../../assets/images/cult-logo.svg'


const toggleClass = () => document.body.classList.toggle("sidebar_close");

const Header = props => {
    const [menu, setMenu] = useState(props.menu);
    const [m_l_1, setM_l_1] = useState('');
    const [m_l_2, setM_l_2] = useState('');
    const userName = localStorage.getItem('userName')
    return (
        <header>
            <div className="cm_logo">
                <Link to="/">
                    <Logo className="headLogo" />
                </Link>
            </div>

            <ul className="header_sub_menu">
                {
                    menu.filter(item => item.path == 'dashboard').length ? <li>
                        <NavLink to="/dashboard" activeClassName="active" onClick={toggleClass}><i className="fa fa-home" />&nbsp; Dashboard</NavLink>
                    </li> : ''
                }

                {menu.filter(item => item.level1 == 'CRM').length ? (
                    <Fragment>
                        <li className={m_l_1.CRM ? 'open_l_menu' : ''}>
                            <span onClick={() => setM_l_1({ CRM: !m_l_1.CRM })}><i className="fa fa-th-large" />&nbsp; CRM <i className="fa fa-angle-down menu_drop_btn" /></span>
                            <ul>
                                {menu.filter(item => item.level1 == 'CRM').map((itm, i) => (
                                    <li key={i}>
                                        <NavLink
                                            onClick={toggleClass}
                                            to={`/${itm.path}`}
                                            activeClassName="active">{itm.label}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </Fragment>
                ) : ''}

                {menu.filter(item => item.level1 == 'HealthServices').length ? (
                    <Fragment>
                        <li className={m_l_1.HealthServices ? 'open_l_menu' : ''}>
                            <span onClick={() => setM_l_1({ HealthServices: !m_l_1.HealthServices })}><i className="fa fa-medkit" />&nbsp; Health Services <i className="fa fa-angle-down menu_drop_btn" /></span>
                            <ul>
                                {menu.filter(item => item.level2 == 'Diet').length ? (
                                    <Fragment>
                                        <li className={m_l_2.Diet ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ Diet: !m_l_2.Diet })}>Diet</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'Diet').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            onClick={toggleClass}
                                                            to={`/${itm.path}`}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }


                                {menu.filter(item => item.level2 == 'Fitness').length ? (
                                    <Fragment>
                                        <li className={m_l_2.Fitness ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ Fitness: !m_l_2.Fitness })}>Fitness</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'Fitness').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            onClick={toggleClass}
                                                            to={`/${itm.path}`}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }


                                {menu.filter(item => item.level2 == 'Configure').length ? (
                                    <Fragment>
                                        <li className={m_l_2.Configure ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ Configure: !m_l_2.Configure })}>Configure</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'Configure').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            onClick={toggleClass}
                                                            to={`/${itm.path}`}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }



                            </ul>
                        </li>
                    </Fragment>
                ) : ''
                }



                {menu.filter(item => item.level1 == 'Live').length ? (
                    <Fragment>
                        <li className={m_l_1.Live ? 'open_l_menu' : ''}>
                            <span onClick={() => setM_l_1({ Live: !m_l_1.Live })}><i className="fa fa-video-camera" />&nbsp; Live <i className="fa fa-angle-down menu_drop_btn" /></span>
                            <ul>
                                {menu.filter(item => item.level1 == 'Live').map((itm, i) => (
                                    <li key={i}>
                                        <NavLink
                                            to={`/${itm.path}`}
                                            onClick={toggleClass}
                                            activeClassName="active">{itm.label}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </Fragment>
                ) : ''
                }



                {menu.filter(item => item.level1 == 'Setup').length ? (
                    <Fragment>
                        <li className={m_l_1.Setup ? 'open_l_menu' : ''}>
                            <span onClick={() => setM_l_1({ Setup: !m_l_1.Setup })}><i className="fa fa-sliders" />&nbsp; Setup <i className="fa fa-angle-down menu_drop_btn" /></span>
                            <ul>
                                {menu.filter(item => item.level2 == 'Catalogue').length ? (
                                    <Fragment>
                                        <li className={m_l_2.Catalogue ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ Catalogue: !m_l_2.Catalogue })}>Catalogue</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'Catalogue').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            to={`/${itm.path}`}
                                                            onClick={toggleClass}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }


                                <Fragment>
                                    <li className={m_l_2.Catalogue ? 'open_l_menu_2' : ''}>
                                        <span>Multiplayer</span>
                                        <i className="fa fa-angle-right menu_drop_btn" />
                                        <ul>
                                            <li>
                                                <NavLink
                                                    to={`/emoji`}
                                                    onClick={toggleClass}
                                                    activeClassName="active">Emoji</NavLink>
                                            </li>

                                        </ul>
                                    </li>
                                </Fragment>



                                {menu.filter(item => item.level2 == 'Customers').length ? (
                                    <Fragment>
                                        <li className={m_l_2.Customers ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ Customers: !m_l_2.Customers })}>Customers</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'Customers').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            to={`/${itm.path}`}
                                                            onClick={toggleClass}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }


                                {menu.filter(item => item.level2 == 'FitServ').length ? (
                                    <Fragment>
                                        <li className={m_l_2.FitServ ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ FitServ: !m_l_2.FitServ })}>FitServ</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'FitServ').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            to={`/${itm.path}`}
                                                            onClick={toggleClass}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }


                                {menu.filter(item => item.level2 == 'Access').length ? (
                                    <Fragment>
                                        <li className={m_l_2.Access ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ Access: !m_l_2.Access })}>Access</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'Access').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            to={`/${itm.path}`}
                                                            onClick={toggleClass}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }


                                {menu.filter(item => item.level2 == 'HealthCoins').length ? (
                                    <Fragment>
                                        <li className={m_l_2.HealthCoins ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ HealthCoins: !m_l_2.HealthCoins })}>HealthCoins</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'HealthCoins').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            to={`/${itm.path}`}
                                                            onClick={toggleClass}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }


                                {menu.filter(item => item.level2 == 'Website').length ? (
                                    <Fragment>
                                        <li className={m_l_2.HealthCoins ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ HealthCoins: !m_l_2.HealthCoins })}>Website</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'Website').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            to={`/${itm.path}`}
                                                            onClick={toggleClass}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }

                                {menu.filter(item => item.level2 == 'UrbanTerrain').length ? (
                                    <Fragment>
                                        <li className={m_l_2.HealthCoins ? 'open_l_menu_2' : ''}>
                                            <span onClick={() => setM_l_2({ HealthCoins: !m_l_2.HealthCoins })}>UrbanTerrain</span>
                                            <i className="fa fa-angle-right menu_drop_btn" />
                                            <ul>
                                                {menu.filter(item => item.level2 == 'UrbanTerrain').map((itm, i) => (
                                                    <li key={i}>
                                                        <NavLink
                                                            to={`/${itm.path}`}
                                                            onClick={toggleClass}
                                                            activeClassName="active">{itm.label}</NavLink>
                                                    </li>
                                                ))}
                                            </ul>
                                        </li>
                                    </Fragment>
                                ) : ''
                                }
                            </ul>
                        </li>
                    </Fragment>
                ) : ''
                }



                {menu.filter(item => item.level1 == 'Others').length ? (
                    <Fragment>
                        <li className={m_l_1.Others ? 'open_l_menu' : ''}>
                            <span onClick={() => setM_l_1({ Others: !m_l_1.Others })}><i className="fa fa-folder" />&nbsp; Others <i className="fa fa-angle-down menu_drop_btn" /></span>
                            <ul>
                                {menu.filter(item => item.level1 == 'Others').map((itm, i) => (
                                    <li key={i}>
                                        <NavLink
                                            to={`/${itm.path}`}
                                            onClick={toggleClass}
                                            activeClassName="active">{itm.label}</NavLink>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </Fragment>
                ) : ''
                }


            </ul>


            <div onClick={toggleClass} className="sidebar_toggle"><i className="fa fa-bars" /></div>
            <div className='d-flex align-items-center'>
                <span className='detail-dispay-in-header'>
                    {userName}
                </span>
                <Dropdown className="header_drops">
                    <Dropdown.Toggle id="dropdown-basic" />
                    <i class="fa fa-ellipsis-v"></i>
                    <Dropdown.Menu>
                        <Link className="dropdown-item" to="/change-password" activeclassname="active">
                            <i className="fa fa-key" />&nbsp; Change Password
                        </Link>
                        <Dropdown.Item onClick={props.handlelogOut}><i className="fa fa-sign-out" />&nbsp; Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </header>
    );
}

export default Header;
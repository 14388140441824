import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HOC from '../../HOC';
import { LoaderSvg } from '../Reusable';
import Pagination from "react-js-pagination";
import { getProductJobList, addProductJob, deleteProductJob, editProductJob, assignMultipleJobToAssociate, updateJobStatus, markProductJobUrgent, exportProductJobRequest, updateFromExcel } from '../../config/services/ProductJob';
import { getNoteList, addNote } from '../../config/services/NoteService';
import { toast } from 'react-toastify';
import { Button, Modal, Alert, FormLabel, Row, Form, FormGroup, FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { getAllVendors, getAllEngineers } from '../../config/services/vendorService';
import { SearchTypes, TatOption,PaymentTypeOptionFilter } from './Constants';
import AsyncSelect from 'react-select/async';
import Popup from "reactjs-popup";
import Select from 'react-select';
import Dropzone from 'react-dropzone';
import PopUpModal from '../../components/common/PopUpModal';
import ProductServiceIncident from '../productServiceIncident';
import { Card } from 'react-bootstrap';
import moment from 'moment';
import { DatePicker } from 'antd';
import { Switch } from 'antd';
import { getAllStates } from '../../config/services/servicePincodes';
import Loader from '../loader';
import { validateRequestId } from '../../utils/regexUtils';
// const { productServiceTypeOptions, statusFilterOptions, sourceFilterOptions, getSortingIcon } = require("../utils/Util.js");

const { RangePicker } = DatePicker;
const { getTableHeader, statusOptions, getValue, productServiceTypeOptions, getSortingIcon,paymentTypeOptions } = require("../utils/Util.js");

const columnDetails = [
    { fieldName: "S.No.", dataIndex: "index", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Job Creation Date", isSortable: true, dataIndex: "created", type: "dateTime", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Request Id", dataIndex: "requestId", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Job Id", dataIndex: "_id", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Order Id", parentDataIndex: "requestServiceData", dataIndex: "orderId", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Product Service Type ", dataIndex: "productServiceType", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Status", dataIndex: "status", type: "combo", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Remarks", dataIndex: "remark", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Is Urgent", dataIndex: "isUrgent", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Is Rescheduled", dataIndex: "isRescheduled", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "TAT Performance", dataIndex: "inTat", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Tat Date", dataIndex: "tatDate", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Vendor", dataIndex: "firstName", parentDataIndex: "vendorData", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Vendor Contact Number", dataIndex: "mobileNo", parentDataIndex: "vendorData", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Associate", dataIndex: "firstName", parentDataIndex: "associateEngineerData", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Associate Contact Number", dataIndex: "mobileNo", parentDataIndex: "associateEngineerData", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Job Close Date", dataIndex: "closeDate", type: "dateTime", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Job Pending Date", dataIndex: "jobPendingDate", type: "dateTime", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Engineer Schedule", dataIndex: "jobScheduleDateTime", type: "dateTime", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Customer Name", dataIndex: "customerId", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Customer Email", dataIndex: "customerEmail", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "CultsportAppDownoaded", dataIndex: "isOneFitPlusDownloadbyVendor", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "CultsportPlayAppDownloaded", dataIndex: "isFitWarzDownloadbyVendor", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "UserStartedLiveSessions", dataIndex: "isTrainerAssigned", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Customer Contact Number", dataIndex: "customerPhNO", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Customer Alternate Number", dataIndex: "customerAltPhNO", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Customer Address", dataIndex: "customerAddress", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "City", dataIndex: "city", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "State", dataIndex: "state", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "PinCode", dataIndex: "pincode", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Brand", dataIndex: "brand", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Model", dataIndex: "model", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Required Part", dataIndex: "spareReceived", type: "string", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Action", dataIndex: "action", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Images", dataIndex: "images", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Payment Type", dataIndex: "paymentType", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Amount", dataIndex: "jobAmount", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Actual Collected Amount", dataIndex: "jobActualCollectedAmount", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Customer Accepted Fees", dataIndex: "isCustomerAcceptedFee", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Issue", dataIndex: "issue", className: "sticky-tableHeader-fixed", role: true },
    { fieldName: "Notes", isSortable: true, dataIndex: "noteData.notes_unread_count", className: "sticky-tableHeader-fixed", role: false },
    { fieldName: "Internal Notes", isSortable: true, dataIndex: "internalNoteData.notes_unread_count", className: "sticky-tableHeader-fixed", role: true },
];

const formField = [
    { fieldName: "Request Id", stateName: "requestId", isRequire: true, type: "text", maxLength: 30, invalidMsg: "Request Id can not be empty" },
    // { fieldName: "Job Type", stateName: "jobTypeId", isRequire: true, type: "text", maxLength: 30, invalidMsg: "Job Type is Require" },
    { fieldName: "Vendor", stateName: "vendorId", isRequire: true, invalidMsg: "Please select vendor" },
    { fieldName: "Associate Engineer", stateName: "associateEngineerId" },
    { fieldName: "Status", stateName: "status", isRequire: true, invalidMsg: "Please select status" }

]

class ProductJob extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            addNewJobLoader: false,
            productJobList: [],
            appliedStatusFilter: [],
            search: '',
            count: 10,
            totalProductJob: 15,
            pageNo: 1,
            sortType: -1,
            sortField: "created",
            addProductJobPopup: false,
            deleteProductJobPopup: false,
            selectedId: "",
            error: "",
            assignEngineer: [],
            requestId: this.props.requestId,
            dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(),
            closeDateFrom: null,
            closeDateTo: null,
            pendingDateFrom: null,
            pendingDateTo: null,
            filterStatus: "",
            filterByTat: "",
            filterByPaymentType:"",
            apiCalled: false,
            requestsExcelSheet: '',
            importExcelPopup: false,
            isUploading: false,
            badEntryErrors: [],
            jobImages: {},
            imagesViewPopup: false,
            remarkViewPopup: false,
            displayRemark: false,
            addProductJobNotesPopup: false,
            showProductJobNotesPopup: false,
            isFoundErrorNoteMessage: false,
            jobNoteLists: [],
            vendorFilterData: "",
            searchField: "",
            searchValue: "",
            jobIdProp: "",
            getServiceIncidentFromJobId: "",
            adminRole: '',
            allstates: [],
            stateFilter: '',
            productjobValidation: false,
            requestIdError: ''
        }
    }

    componentDidMount() {
        this.getProductJobList();
        this.getVendors();

        // get all states
        this.getAllState()

        // get admin role
        let role = localStorage.getItem('role')
        this.setState({
            adminRole: role
        })
    }

    // handle State
    handleStateChange = (value, fieldName, callListApi) => {
        this.setState({ [fieldName]: value });
    }

    // get all states
    getAllState = () => {
        const { statePageNo, stateCount, allstates } = this.state;
        let params = { search: '', sortField: "_id", sortType: 1, pageNo: statePageNo, count: stateCount }
        getAllStates({ params })
            .then(res => {
                let response = res.data;
                if (response.statusCode == 1) {
                    console.log("states", response)
                    let data = res.data.responseData
                    let totalCount = data.totalCount;
                    let pgno = statePageNo + 1;

                    if (data.result && data.result.length > 0) {
                        data.result.forEach(key => {
                            allstates.push({
                                label: key._id,
                                value: key._id,
                            })
                        })
                    }

                    // setAllstates(allstates);
                    // setState({
                    //   ...state,
                    //   statePageNo: pgno
                    // })
                    // state.statePageNo = pgno;
                    // state.states = allstates;
                    // if (totalCount > (pgno * stateCount)) {
                    //     getStateLists();
                    // }
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    }

    // handle search all data & filter
    handleSearchData = () => {
        let { dateFrom, dateTo, closeDateFrom, closeDateTo, pendingDateFrom, pendingDateTo, filterStatus, vendorFilterData, filterByTat,filterByPaymentType, searchField, searchValue } = this.state;
        if (!dateFrom && !dateTo && !closeDateFrom && !closeDateTo && !pendingDateFrom && !pendingDateTo && !filterStatus && (!vendorFilterData && !vendorFilterData.value) && !filterByTat && !filterByPaymentType && !searchField && !searchValue) {
            toast.error('Please select any value');
        } else if (!searchField && searchValue) {
            toast.error('Please select search field');
        } else if (searchField && !searchValue) {
            toast.error('Please enter search value');
        } else {
            this.setState({ apiCalled: true, pageNo: 1, sortField: "created" }, () => this.getProductJobList())
        }
        this.getCorrectJobId()
    }

    getCorrectJobId = () => {
        if (this.state.searchField.value == "_id" && this.state.searchField.value == SearchTypes.filter((a) => a.value == '_id')[0].value) {
            let jobId = this.state.searchField.value == SearchTypes.filter((a) => a.value == '_id')[0].value ? this.state.searchValue :
                this.state["selectedJobId"]
            this.setState({
                jobIdProp: jobId
            })
        }
    }

    // search on enter key
    handleSearchByEnter = (e) => {
        if (e.target.value.length > 0) {
            if (e.key === 'Enter') {
                this.getProductJobList();
            }
        }
    }


    handleSort = (field) => {
        let { sortType, sortField } = this.state;
        let orderType = 1;
        if (sortType && sortField === field) {
            orderType = sortType < 0 ? '' : -1;
        }
        this.setState({
            sortField: field,
            sortType: orderType
        }, () => this.getProductJobList());
    }


    getProductJobList = (isExport) => {
        let { search, searchField, searchValue, count, pageNo, sortType, sortField, dateFrom, dateTo, closeDateFrom, closeDateTo,pendingDateTo,pendingDateFrom, vendorFilterData, filterStatus, filterByTat,filterByPaymentType, productJobList, stateFilter } = this.state;
        let { isFromUser, requestId } = this.props;

        let paramsObj = {
            params: {
                search, count, pageNo: pageNo - 1, sortType, sortField,
                requestId: requestId,
                dateFrom: !isFromUser ? dateFrom ? moment(dateFrom).startOf('day').valueOf() : null : null,
                dateTo: !isFromUser ? dateTo ? moment(dateTo).endOf('day').valueOf() : null : null,
                closeDateFrom: !isFromUser ? closeDateFrom ? moment(closeDateFrom).startOf('day').valueOf() : null : null,
                closeDateTo: !isFromUser ? closeDateTo ? moment(closeDateTo).endOf('day').valueOf() : null : null,
                pendingDateFrom: !isFromUser ? pendingDateFrom ? moment(pendingDateFrom).startOf('day').valueOf() : null : null,
                pendingDateTo: !isFromUser ? pendingDateTo ? moment(pendingDateTo).endOf('day').valueOf() : null : null,
                filterStatus: filterStatus,
                vendorId: vendorFilterData && vendorFilterData.value,
                filterByTat: filterByTat,
                filterByPaymentType: filterByPaymentType,
                searchField: isFromUser && requestId != '' ? 'requestId' : searchField.value,
                searchValue: isFromUser && requestId != '' ? requestId : searchValue.trim(),
                stateFilter: stateFilter ? stateFilter : ''
            }
        }

        this.setState({
            loader: true,
            getServiceIncidentFromJobId: ''
        })

        if (isExport) {
            if (productJobList.length > 0) {
                var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                paramsObj.params.tz = tz;
                exportProductJobRequest(paramsObj).then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.setState({ apiCalled: false })
                        window.open(response.responseData.result.Location, "_blank");

                    } else if (response.statusCode == 0) {
                        this.setState({ apiCalled: false })
                        toast.error(response.error.errorMessage)
                    }
                    this.setState({
                        loader: false
                    })
                })
            }
            else {
                this.setState({ apiCalled: false, loader: false })
                toast.error("No data available to export")
            }
        } else {
            getProductJobList(paramsObj)
                .then(res => {
                    let response = res.data
                    if (response.statusCode == 1) {
                        if (response.responseData.result.data && response.responseData.result.data[0]) {
                            this.setState({
                                getServiceIncidentFromJobId: response.responseData.result.data[0]._id
                            })
                        } else {
                            this.setState({
                                getServiceIncidentFromJobId: 'not_found_any_jobs'
                            })
                        }

                        this.setState({
                            productJobList: response.responseData.result.data,
                            totalProductJob: response.responseData.result.totalCount,
                            loader: false,
                            apiCalled: false,
                        })
                    } else if (response.statusCode == 0) {
                        this.setState({ loader: false, apiCalled: false })
                        toast.error(response.error.errorMessage)
                    }
                })
        }
    }
    handlePageChange = (pageNumber) => { this.setState({ pageNo: pageNumber }, () => { this.getProductJobList() }) }

    handleClose = () => { this.setState({ addProductJobPopup: false, error: "", jobTypeId: "", vendorId: "", status: "", requestId: "", productServiceType: "", paymentType:"", jobAmount:"", issue: "" }) }

    handleAddProductJobPopup = (data) => {
        if (data._id) {
            this.getProductJob(data)
        } else {
            this.setState({ addProductJobPopup: true, selectedId: "", selectedAssociate: [], selectedVendor: [] })
        }
    }

    getProductJob = (job) => {
        let selectedVendor = job.vendorData && [{ label: `${job.vendorData.firstName} ${job.vendorData.lastName}`, value: job.vendorData._id }]
        let selectedAssociate = job.associateEngineerData && [{ label: `${job.associateEngineerData.firstName} ${job.associateEngineerData.lastName}`, value: job.associateEngineerData._id }]
        this.setState({
            // jobTypeId: job.jobTypeId,
            vendorId: job.vendorData._id,
            status: job.status,
            selectedId: job._id,
            requestId: job.requestId,
            paymentType: job.paymentType,
            jobAmount : job.jobAmount,
            issue: job.issue,
            addProductJobPopup: true,
            selectedVendor: selectedVendor,
            selectedAssociate: selectedAssociate,
            associateEngineerId: job.associateEngineerData && job.associateEngineerData._id,
            productServiceType: job.productServiceType
        })

    }

    handleChange = (e, type, field, length) => {
        type ? this.setState({ [type]: e, error: "" }) : this.setState({ [e.target.name]: e.target.value, error: e.target.value.length > length ? `${field} has reached max length` : "" });

    }
    handleSubmit = (event) => {
        let { isFromUser, requestId } = this.props;

        let self = this;
        

        event.preventDefault();
        event.stopPropagation();
        let { success, message } = validateRequestId(this.state.requestId);
        if (!success) {
            self.setState({ error: message })
            return
        }
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true })
            return;
        }
        if (!self.state["vendorId"] || !Number(self.state["status"])) {
            this.setState({ validated: true })
            return;
        }

        let params = {
            vendorId: self.state["vendorId"],
            associateEngineerId: self.state["associateEngineerId"],
            status: Number(self.state["status"]),
            productServiceType: self.state["productServiceType"],
            paymentType: Number(self.state["paymentType"]),
            jobAmount: Number(self.state["jobAmount"]),
            issue: self.state["issue"]
        }

        if (!isFromUser) {
            params.requestId = self.state["requestId"].trim()
        } else {
            params.requestId = requestId.trim()
        }
        self.setState({ addNewJobLoader: true })

        let apiObject = addProductJob(params);
        apiObject.then(res => {
            self.setState({ loader: false, addNewJobLoader: false })
            const response = res.data;
            if (response.statusCode == 1) {
                self.setState({ selectedId: "" })
                toast.success(response.responseData.message)
                self.handleClose();
                self.getProductJobList();
            } else if (response.statusCode == 0) {
                self.setState({ error: response.error.errorMessage })
            }
        })

    }
    handleEditSubmit = (event) => {
        let self = this;
        let isError = false;

        event.preventDefault();
        event.stopPropagation();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            this.setState({ validated: true })
            return;
        }
        if (!self.state["vendorId"] || !Number(self.state["status"])) {
            this.setState({ validated: true })
            return;
        }

        let params = {
            requestId: self.state["requestId"],
            // jobTypeId: self.state["jobTypeId"],
            vendorId: self.state["vendorId"],
            associateEngineerId: self.state["associateEngineerId"],
            status: Number(self.state["status"]),
            productServiceType: self.state["productServiceType"],
            paymentType: Number(self.state["paymentType"]),
            jobAmount: Number(self.state["jobAmount"]),
            issue: self.state["issue"]
        }

        self.setState({ addNewJobLoader: true })
        params["jobId"] = self.state.selectedId;
        let apiObject = editProductJob(params);
        apiObject.then(res => {
            self.setState({ loader: false, addNewJobLoader: false })
            const response = res.data;
            if (response.statusCode == 1) {
                self.setState({ selectedId: "" })
                toast.success(response.responseData.message)
                self.handleClose();
                self.getProductJobList();
            } else if (response.statusCode == 0) {
                self.setState({ error: response.error.errorMessage })
            }
        })
    }
    openDeleteProductJobPopup = (e, productJob) => {
        this.setState({ deleteProductJobPopup: !this.state.deleteProductJobPopup, tempJobId: productJob._id })
    };

    deleteProductJob = (e) => {
        e.preventDefault();
        const { tempJobId } = this.state
        let paramsObj = {
            jobId: tempJobId
        }
        deleteProductJob(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ loader: false })
                    this.closeModal();
                    this.getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }
    closeModal = () => this.setState({ deleteProductJobPopup: false, tempJobId: '', importExcelPopup: false, badEntryErrors: [], isUploading: false, requestsExcelSheet: '', validationError: '', validationSuccess: '', imagesViewPopup: false, jobImages: {}, displayRemark: '', remarkViewPopup: false, showProductJobNotesPopup: false, addProductJobNotesPopup: false })

    getVendors = (val) => {
        let paramsobj = { params: { count: 0, pageNo: 0, search: val } }
        return getAllVendors(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let result = res.data.responseData.vendors;
                    let vendors = []
                    result && result.map(vendor => {
                        vendors.push({ label: `${vendor.firstName} ${vendor.lastName}`, value: vendor._id })
                    })
                    this.setState({ vendors })
                    return vendors
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }
    // get Engineer data
    getAssociateEngineer = (val) => {
        let paramsobj = { params: { vendorId: this.state.vendorId } }
        return getAllEngineers(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let result = res.data.responseData.engineers;
                    let associateEngineers = []
                    result && result.map(associateEngineer => {
                        associateEngineers.push({ label: `${associateEngineer.firstName} ${associateEngineer.lastName}`, value: associateEngineer._id })
                    })
                    this.setState({ associateEngineers })
                    return associateEngineers
                }
                else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
    }

    loadVendors = (val) => {
        return this.getVendors(val)
    }

    loadOptionByval = (val) => {
        return this.getVendors(val)
    }

    vendorSelect = (vendor) => {
        this.setState({ vendorId: vendor ? vendor.value : "", selectedVendor: vendor, selectedAssociate: [] });
        if (vendor) {
            this.loadAssociateEngineer();
        }
    }

    engineerSelect = (engineer) => {
        this.setState({ associateEngineerId: engineer.value, selectedAssociate: engineer })
    }
    loadAssociateEngineer = (val) => {
        return this.getAssociateEngineer(val)
    }

    onCheckHandle = (e, value) => {
        let { assignEngineer, productJobList } = this.state;
        if (e.target.checked) {
            if (value === "all") {
                productJobList.forEach(element => {
                    !element.isAssigned && assignEngineer.push(element._id);
                });
            }
            else {
                !assignEngineer.includes(value) && assignEngineer.push(value);
            }

        }
        else {
            if (value !== "all") {

                assignEngineer = assignEngineer.filter(item => item !== value)
            }
            else {
                assignEngineer = []
            }

        }
        this.setState({ assignEngineer: assignEngineer })
    }

    assignJobToEngineer = () => {
        let { assignEngineer, assignEnigneerSelectedId } = this.state;
        let paramsObj = {
            jobIds: JSON.stringify(assignEngineer),
            engineerId: assignEnigneerSelectedId
        }
        assignMultipleJobToAssociate(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ assignEngineer: [], assignEnigneerSelected: false });
                    this.getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })

    }

    assignEnigneerSelect = (val) => {
        this.setState({ assignEnigneerSelected: !this.state.assignEnigneerSelected, assignEnigneerSelectedId: val ? val.value : "", assignEnigneerSelectedName: val, validationError: this.state.assignEngineer.length == 0 ? " Please Select Jobs " : "" })
    }
    changeStatus = (statusValue, id) => {
        this.setState({ statusPopup: true, selectedStatus: statusValue, selectedJobForJobId: id })
    }
    closeStatusPopup = () => {
        this.setState({ statusPopup: false, selectedStatus: "", selectedJobForJobId: "" })
    }

    changeJobStaus = () => {
        let { selectedJobForJobId, selectedStatus } = this.state;
        let paramsObj = {
            jobId: selectedJobForJobId,
            jobStatus: selectedStatus
        }
        updateJobStatus(paramsObj)
            .then((res) => {
                if (res.data.statusCode == 1) {
                    this.setState({ selectedJobForJobId: "", statusValue: "", statusPopup: false });
                    this.getProductJobList();
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })

    }

    filterByDate = (date) => {
        let dateFrom = null, dateTo = null, closeDateFrom = null, closeDateTo = null, pendingDateFrom=null, pendingDateTo=null;
        if (date) {
            dateFrom = moment(date[0]).startOf('day').valueOf()
            dateTo = moment(date[1]).endOf('day').valueOf()
        }
        this.setState({
            closeDateFrom, closeDateTo, dateFrom, dateTo,pendingDateFrom,pendingDateTo
            // apiCalled: true,
            // pageNo: 1
        })
    }

    filterByCloseDate = (date) => {
        let closeDateFrom = null, closeDateTo = null, dateFrom = null, dateTo = null,pendingDateFrom=null, pendingDateTo=null;
        if (date) {
            closeDateFrom = moment(date[0]).startOf('day').valueOf()
            closeDateTo = moment(date[1]).endOf('day').valueOf()
        }
        this.setState({
            closeDateFrom, closeDateTo, dateFrom, dateTo,pendingDateFrom,pendingDateTo
            // apiCalled: true, pageNo: 1
        })
    }

    filterByPendingDate = (date) => {
        let pendingDateFrom = null, pendingDateTo = null, dateFrom = null, dateTo = null,closeDateFrom = null, closeDateTo = null;
        if (date) {
            pendingDateFrom = moment(date[0]).startOf('day').valueOf()
            pendingDateTo = moment(date[1]).endOf('day').valueOf()
        }
        this.setState({
            closeDateFrom, closeDateTo, dateFrom, dateTo,pendingDateFrom,pendingDateTo
            // apiCalled: true, pageNo: 1
        })
    }

    handleFilterChange = (value, fieldName) => {
        if (fieldName == 'filterStatus') {
            let seachStatus = [];
            let foundAll = 0;
            value && value.length && value.map((item) => {
                if (item.label == "ALL") {
                    foundAll = 1;
                } else {
                    seachStatus.push(item.value);
                }
            });

            if (foundAll == 1) {
                if (value.length > 1) {
                    var lastIndex = value[value.length - 1].label;
                    if (lastIndex == 'ALL') {
                        seachStatus = [];
                        value = [];
                        var status = {};
                        status.label = 'ALL';
                        status.value = '';
                        value.push(status);
                    } else {
                        value = value.filter(function (item) { return item.label != 'ALL' })
                    }
                }
            }

            seachStatus = seachStatus.join();
            this.setState({
                appliedStatusFilter: value, [fieldName]: seachStatus,
                // apiCalled: true
            });
        } else {
            this.setState({
                [fieldName]: value,
                // apiCalled: true
            });
        }
    }

    onHandleSelect = (data, fieldName) => {
        let fieldNameData = `${fieldName}Data`
        this.setState({
            [fieldName]: data.label, vendorFilter: data._id, [fieldNameData]: data,
            // apiCalled: true
        })
    }

    handleSearchType = (search) => {
        this.setState({ searchField: search, searchValue: '', searchError: '' });
    }

    handleSearch = (e) => {
        let { value } = e.target
        let { searchField } = this.state
        this.setState({ searchValue: value });

        if (!searchField) {
            this.setState({ searchError: 'Select Search Field' })
        } else {
            e.persist();
            this.setState({
                searchError: '',
                // apiCalled: true, pageNo: 1
            });
            // clearTimeout(this.timer)
            // this.timer = setTimeout(() => {
            //     this.setState({ apiCalled: false })
            //     this.getProductJobList();
            // }, 400)
        }
    }

    resetFilter = () => {
        this.setState({
            searchField: '', searchValue: '', search: '', dateFrom: moment().startOf('day').valueOf(),
            dateTo: moment().endOf('day').valueOf(), closeDateFrom: '', closeDateTo: '',pendingDateTo:'',pendingDateFrom:'', filterStatus: "", appliedStatusFilter: [], filterByTat: '',filterByPaymentType:'', pageNo: 1, filterStateData: '', Loader: true,
            vendorFilterData: "", stateFilter: '', selectedState: []
        }, () => this.getProductJobList())
    }

    handleChangeStatusUrgent(id, status, e) {
        let loadingState = `urgentLoading${id}`
        this.setState({ [loadingState]: true });
        let self = this;
        let obj = {
            jobId: id,
            isUrgent: !status
        }

        markProductJobUrgent(obj)
            .then((res) => {
                if (res.data.statusCode === 1) {
                    self.getProductJobList();
                    this.setState({ [loadingState]: false })
                }
                else if (res.data.statusCode == 0) {
                    this.setState({ loader: false })
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    onJobClick = (jobId) => {
        // this.setState({ getServiceIncidentFromJobId: jobId }, () => this.getProductJobList());        
        this.setState({ loader: true, getServiceIncidentFromJobId: jobId }, function () {
            this.setState({ loader: false });
        });
    }

    onCustomerClick = (jobId) => {
        var win = window.open('/profile/' + jobId, '_blank')
        win.focus();
    }

    // excel export function
    showHideExport = () => {
        this.setState({ showHideExport: true });
    }

    exportHandler = () => {
        this.setState({ apiCalled: false, showHideExport: false }, function () {
            this.getProductJobList(true)
        });
    }

    // excel import process
    importHandler = () => {
        this.setState({ importExcelPopup: true, validationError: '', validationSuccess: '' })
    }

    handleFileDrop = (accepted, rejected) => {
        if (accepted && accepted.length) {
            this.setState({ requestsExcelSheet: accepted[0], validationError: '', validationSuccess: '' })
        }
        else if (rejected && rejected.length) {
            this.setState({ validationError: "This file format is not supported. Format should be 'xls, xlsx or csv'", requestsExcelSheet: '' })
        }
    }

    submitValidateExcelUpload = (e) => {
        e.preventDefault();
        const { requestsExcelSheet } = this.state;
        if (!requestsExcelSheet) {
            this.setState({ validationError: 'Please select a file!' })
            return;
        }
        else {
            let params = new FormData()
            params.append('requestsExcelSheet', requestsExcelSheet);
            this.setState({ validationError: '', isUploading: true })
            updateFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode == 1) {
                        this.setState({ validationSuccess: 'file imported successfully' });
                        // let responseData = response.responseData.result;
                        // if(responseData.successUpdateJobId) {
                        //     let getSuccess = '';
                        //     responseData.successUpdateJobId.forEach(e => {
                        //         var err = '<div>'
                        //         err += e;
                        //         err += '</div>';   
                        //         getSuccess += err;  
                        //     })
                        //     this.setState({ validationSuccess: getSuccess });
                        // }
                        // if(responseData.failUpdateJobId && responseData.failUpdateJobId.length > 0) {
                        //     let getError = '';
                        //     responseData.failUpdateJobId.forEach(error => {
                        //         var err = '<div>'
                        //         err += error.JobId;
                        //         if(error.err.length > 0) {
                        //             err += ' : ';
                        //             error.err.forEach(e => {
                        //                 err += e;
                        //             })
                        //         }
                        //         err += '</div>';   
                        //         getError += err;                             
                        //     })
                        //     this.setState({ validationError: getError });
                        // }         
                        // importExcelPopup: false,      
                        this.setState({ isUploading: false })
                    } else if (response.statusCode == 0) {
                        this.setState({ isUploading: false })
                        this.setState({ validationError: response.error.errorMessage })
                    }
                    this.setState({ isUploading: false })
                })
        }
    }

    openRemarkView = (remark) => {
        this.setState({ remarkViewPopup: true, displayRemark: remark });
    }

    openImageVideoView = (jobDetails) => {
        let { afterImage, beforeImage, afterImages, beforeImages } = jobDetails;
        let { jobImages } = this.state;

        jobImages['beforeImage'] = beforeImage ? beforeImage : '';
        jobImages['afterImage'] = afterImage ? afterImage : '';
        jobImages['beforeImages'] = beforeImages && beforeImages.length > 0 ? beforeImages : '';
        jobImages['afterImages'] = afterImages && afterImages.length > 0 ? afterImages : '';
        jobImages['before_file_type'] = '';
        jobImages['before_img_url'] = '';
        jobImages['before_video_url'] = '';
        jobImages['before_video_mimetype'] = '';
        jobImages['after_file_type'] = '';
        jobImages['after_img_url'] = '';
        jobImages['after_video_url'] = '';
        jobImages['after_video_mimetype'] = '';

        if (beforeImages && beforeImages[0] && beforeImages[0].url) {
            jobImages['before_file_type'] = beforeImages[0].file_type;
            jobImages['before_img_url'] = beforeImages[0].file_type == 'image' ? beforeImages[0].url : beforeImages[0].thumbnail;
            jobImages['before_video_url'] = beforeImages[0].url;
            jobImages['before_video_mimetype'] = beforeImages[0].mimetype;
        }
        if (afterImages && afterImages[0] && afterImages[0].url) {
            jobImages['after_file_type'] = afterImages[0].file_type;
            jobImages['after_img_url'] = afterImages[0].file_type == 'image' ? afterImages[0].url : afterImages[0].thumbnail;
            jobImages['after_video_url'] = afterImages[0].url;
            jobImages['after_video_mimetype'] = afterImages[0].mimetype;
        }

        this.setState({ imagesViewPopup: true, jobImages });
    }

    openBeforeImageVideoView = (file) => {
        let { jobImages } = this.state;
        jobImages['before_file_type'] = '';
        jobImages['before_img_url'] = '';
        jobImages['before_video_url'] = '';
        jobImages['before_video_mimetype'] = '';
        if (file && file.url) {
            jobImages['before_file_type'] = file.file_type;
            jobImages['before_img_url'] = file.file_type == 'image' ? file.url : file.thumbnail;
            jobImages['before_video_url'] = file.url;
            jobImages['before_video_mimetype'] = file.mimetype;
        }
        this.setState({ jobImages });
    }

    openAfterImageVideoView = (file) => {
        let { jobImages } = this.state;
        jobImages['after_file_type'] = '';
        jobImages['after_img_url'] = '';
        jobImages['after_video_url'] = '';
        jobImages['after_video_mimetype'] = '';
        if (file && file.url) {
            jobImages['after_file_type'] = file.file_type;
            jobImages['after_img_url'] = file.file_type == 'image' ? file.url : file.thumbnail;
            jobImages['after_video_url'] = file.url;
            jobImages['after_video_mimetype'] = file.mimetype;
        }
        this.setState({ jobImages });
    }


    handleAddProductJobNotesPopup = (jobInfo, notesType) => {
        let currentStatus = statusOptions.find(status => status.value === jobInfo.status);
        this.setState({
            addProductJobNotesPopup: true,
            noteJobId: jobInfo._id,
            noteMessage: '',
            noteState: currentStatus && currentStatus.label ? currentStatus.label : ''
        })
        if(notesType === "internalNotes"){
            this.setState({
                internalNotes : notesType
            })
        }
    }

    addJobNoteAction = (e) => {
        e.preventDefault();
        let fonndError = false;
        this.setState({
            addNoteloader: false,
            isFoundErrorNoteMessage: false
        })
        const { noteJobId, noteMessage, noteState } = this.state;

        if (noteMessage == '') {
            fonndError = true;
            this.setState({ isFoundErrorNoteMessage: true })
        }

        if (!fonndError) {
            this.setState({
                addNoteloader: true,
            });

            let params = {};
            params.refId = noteJobId;
            params.content = noteMessage;
            params.state = noteState;
            if (this.state.internalNotes === "internalNotes") {
                params.internalNotes = true
                this.addNotes(params)
            } else {
                this.addNotes(params)
            }
        }
    }

    addNotes = (params) => {
        addNote(params)
            .then((response) => {
                if (response && response.data && response.data.responseData) {
                    this.setState({
                        addNoteloader: false,
                        addProductJobNotesPopup: false,
                        internalNotes: ''
                    })
                    toast.success(response.data.responseData.message)
                } else if (response.data.statusCode == 0) {
                    this.setState({ addNoteloader: false })
                    toast.error(response.data.error.errorMessage)
                }
            })
            .catch((err) => {
                console.log('error', err);
            })
    }

    handleShowProductJobNotesPopup = (jobInfo, internalNotes) => {
        this.setState({
            showProductJobNotesPopup: true,
            jobNoteLists: []
        })
        if (internalNotes) {
            let paramsobj = { params: { refId: jobInfo._id, internalNotes: true } }
            this.viewNotes(jobInfo, paramsobj, internalNotes);
        } else {
            let paramsobj = { params: { refId: jobInfo._id } }
            this.viewNotes(jobInfo, paramsobj);
        }
    }

    viewNotes = (jobInfo, paramsobj, internalNotes) => {
        getNoteList(paramsobj)
            .then(res => {
                if (res.data.statusCode == 1) {
                    let result = res.data.responseData.result;
                    this.setState({ jobNoteLists: result })

                    if (internalNotes) {
                        if (jobInfo['internalNoteData'] && jobInfo['internalNoteData']['notes_unread_count'] > 0) {
                            jobInfo['internalNoteData']['notes_unread_count'] = 0;
                        }
                    } else {
                        if (jobInfo['noteData'] && jobInfo['noteData']['notes_unread_count'] > 0) {
                            jobInfo['noteData']['notes_unread_count'] = 0;
                        }
                    }

                } else if (res.data.statusCode == 0) {
                    console.log(res.data.error.errorMessage)
                }
            })
            .catch((err) => {
                console.log('error', err);
            })
    }




    render() {
        let {
            loader, productJobList, search, pageNo, count, totalProductJob, addProductJobPopup, error, deleteProductJobPopup, vendors, associateEngineers, assignEngineer, assignEnigneerSelected, assignEnigneerSelectedName, validationError, validationSuccess,
            dateFrom, dateTo, closeDateFrom, closeDateTo,pendingDateTo, pendingDateFrom, statusPopup, validated, urgentLoading, apiCalled, importExcelPopup, badEntryErrors, isUploading, jobImages, imagesViewPopup, appliedStatusFilter, addNewJobLoader, sortType, sortField, adminRole, allstates, productjobValidation } = this.state;
        let { isFromUser, requestId } = this.props;
        let isRequestIdLinked = this.props.history ? true : false;
        if (!this.state["selectedJobId"] && productJobList.length > 0) {
            this.state["selectedJobId"] = productJobList[0]._id;
        }

        return (
            <HOC>
                {apiCalled && <div className="loader_wrapper"><LoaderSvg /></div>}
                <div className={!isFromUser ? "body-container-wrapper" : ""} >
                    <div className={!isFromUser ? "body-container" : ""}>
                        <ol className="breadcrumb" style={{ display: isFromUser ? "none" : "" }}>
                            <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item">Product Job</li>
                        </ol>

                        {loader && <div className="loader_wrapper"><LoaderSvg /></div>}
                        <div className="users_header" style={{ display: isFromUser ? "none" : "" }}>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4 className="cm_page_heading mt-1 mb-0">Product Job</h4>
                                    </div>
                                    <div className="col-md-6 list-inline-item text-right">
                                        {!isFromUser ?
                                            adminRole != 7 ?
                                                <button className="btn btn-primary" onClick={this.handleAddProductJobPopup}>Add New Job</button>
                                                : '' :
                                            ''}
                                        {adminRole != 7 ?
                                            <Button className='ml-1' type="primary" onClick={this.importHandler}>Import</Button> : ""}
                                        {/* {productJobList.length ? <ExportExcel  productJobs={productJobList} /> : null} */}
                                        <Button disabled={apiCalled} className='ml-1' type="primary" onClick={this.showHideExport}>Export</Button>
                                    </div>

                                </div>
                            </div>
                        </div>



                        {!isFromUser &&
                            <div className="row mb-2">
                                <div className="col-xl-12 align-items-baseline d-inline-flex ">

                                    <ul className="d-flex mb-0 flex-wrap">
                                        <li className="list-inline-item d-flex flex-column mt-2 range-wrapper-width">
                                            <label inline className="mr-2">Filter By Date:</label>
                                            <RangePicker
                                                style={{ height: "2.8em" }}
                                                size={"middle"}
                                                onChange={(val) => this.filterByDate(val)}
                                                value={dateFrom && dateTo && [moment(dateFrom), moment(dateTo)]}
                                                defaultValue={[moment(dateFrom), moment(dateTo)]}
                                            />
                                        </li>

                                        <li className="list-inline-item d-flex flex-column mt-2 range-wrapper-width">
                                            <label inline className="mr-2">Filter By Close Date:</label>
                                            <RangePicker
                                                style={{ height: "2.8em" }}
                                                size={"middle"}
                                                name="filterByCloseDate"
                                                onChange={(val) => this.filterByCloseDate(val)}
                                                value={closeDateFrom && closeDateTo && [moment(closeDateFrom), moment(closeDateTo)]}
                                                defaultValue={[moment(closeDateFrom), moment(closeDateTo)]}
                                            />
                                        </li>
                                        <li className="list-inline-item d-flex flex-column mt-2 range-wrapper-width">
                                            <label inline className="mr-2">Filter By Pending Date:</label>
                                            <RangePicker
                                                style={{ height: "2.8em" }}
                                                size={"middle"}
                                                name="filterByPendingDate"
                                                onChange={(val) => this.filterByPendingDate(val)}
                                                value={pendingDateFrom && pendingDateTo && [moment(pendingDateFrom), moment(pendingDateTo)]}
                                                defaultValue={[moment(pendingDateFrom), moment(pendingDateTo)]}
                                            />
                                        </li>
                                        <li className="list-inline-item mt-2">
                                            <label inline className="mr-2">Status:</label>
                                            <Select
                                                isMulti
                                                className='cm_min_width'
                                                onChange={(e) => this.handleFilterChange(e, "filterStatus")}
                                                placeholder="Status"
                                                options={statusOptions}
                                                value={appliedStatusFilter}
                                                classNamePrefix="custom"
                                            />
                                        </li>
                                        <li className="list-inline-item mt-2">
                                            <label inline='true' className="mr-2">State:</label>
                                            <Select
                                                className='cm_min_width'
                                                onChange={(e) => this.handleStateChange(e.value, "stateFilter", 1)}
                                                placeholder="Filter By State"
                                                options={allstates}
                                                value={this.state.stateFilter ? allstates.find(element => element.value === this.state.stateFilter) : null}
                                            />
                                        </li>
                                        {adminRole != 7 ?
                                            <>
                                                <li className="list-inline-item mt-2">
                                                    <label inline className="mr-2">Vendor:</label>
                                                    <AsyncSelect
                                                        className='cm_min_width'
                                                        isDisabled={false}
                                                        cacheOptions
                                                        value={this.state["vendorFilterData"]}
                                                        loadOptions={this.loadVendors}
                                                        onFocus={() => this.loadVendors()}
                                                        defaultOptions={vendors}
                                                        onChange={(val) => this.onHandleSelect(val, "vendorFilter")}
                                                        placeholder="Select Vendor"
                                                        classNamePrefix="custom"
                                                    />
                                                </li>

                                                <li className="list-inline-item mt-2">
                                                    <label inline className="mr-2">Tat:</label>
                                                    <Select
                                                        classNamePrefix="custom"
                                                        isSearchable
                                                        options={TatOption}
                                                        placeholder="Select Tat"
                                                        className="cm_min_filter_width"
                                                        onChange={(e) => this.handleFilterChange(e.value, "filterByTat")}
                                                        value={this.state['filterByTat'] ? TatOption.find(element => element.value === this.state['filterByTat']) : ''}
                                                    />
                                                </li>
                                                <li className="list-inline-item mt-2">
                                                    <label inline className="mr-2">Payment Type:</label>
                                                    <Select
                                                        classNamePrefix="custom"
                                                        isSearchable
                                                        options={PaymentTypeOptionFilter}
                                                        placeholder="Select Tat"
                                                        className="cm_min_filter_width"
                                                        onChange={(e) => this.handleFilterChange(e.value, "filterByPaymentType")}
                                                        value={this.state['filterByPaymentType'] ? PaymentTypeOptionFilter.find(element => element.value === this.state['filterByPaymentType']) : ''}
                                                    />
                                                </li>
                                            </>
                                            : ""
                                        }
                                        <li className="list-inline-item mt-2">
                                            <label inline className="mr-2">Field:</label>
                                            <Select
                                                classNamePrefix="custom"
                                                isSearchable
                                                options={SearchTypes}
                                                placeholder="Search Field"
                                                className="cm_min_filter_width"
                                                onChange={(val) => this.handleSearchType(val)}
                                                value={this.state["searchField"]}
                                            />
                                        </li>
                                        <li className="list-inline-item mt-2">
                                            <label inline className="mr-2">Search:</label>
                                            <FormControl id="search" className="h-auto" name='search' value={this.state.searchValue} placeholder="Search here......" onChange={this.handleSearch} onKeyPress={this.handleSearchByEnter} />
                                        </li>

                                        <li className="list-inline-item align-self-end">
                                            <Button variant="primary" className="btn-reset" style={{ marginBottom: '2px' }} size='sm' onClick={this.handleSearchData} > <i className="fa fa-search" /></Button>
                                        </li>
                                        <li className="list-inline-item align-self-end">
                                            <Button variant="outline-dark" className="btn-reset" style={{ marginBottom: '2px' }} size='sm' onClick={this.resetFilter} > <i className="fa fa-refresh" /></Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        }


                        <div className="table-responsive">
                            <table className="table table-bordered table-striped text-center mb-2">

                                <thead>
                                    <tr>
                                        {columnDetails.map((item, i) => {
                                            let { isSortable, dataIndex } = item;
                                            return (isSortable && adminRole != 7) || (isSortable && adminRole == 7 && item.role)
                                                ? <th scope="col" key={i} className="filter_text" onClick={() => this.handleSort(dataIndex)}>{item.fieldName} &nbsp;  <i className={getSortingIcon(dataIndex == sortField && sortType)} /></th>
                                                :
                                                (adminRole == 7 && item.role == false) ?
                                                    ''
                                                    :
                                                    <th scope="col" key={i}>{item.fieldName}</th>
                                        })}
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        productJobList && productJobList.map((details, i) => {
                                            return (
                                                <tr key={i}>
                                                    {
                                                        columnDetails.map((item) => {
                                                            let col = [];
                                                            if (item.dataIndex == "index") {
                                                                col.push(<td>{((i + 1) + ((pageNo - 1) * count))}</td>)
                                                            }else if(item.dataIndex =="productServiceType"){
                                                                col.push(<td>{details["productServiceType"] === 1 ? "INSTALLATION" : details["productServiceType"] === 2 ? "REPAIR" : details["productServiceType"] === 3 ? "UNINSTALLATION" : details["productServiceType"] === 4 ? 'REVISIT' : '-'}</td>)
                                                            }else if(item.dataIndex =="paymentType"){
                                                                col.push(<td>{details["paymentType"] === 1 ? "FREE" : details["paymentType"] === 2 ? "PREPAID" : details["paymentType"] === 3 ? "COD" : '-'}</td>)
                                                            }
                                                            else if (item.dataIndex == "isUrgent" && adminRole != 7) {
                                                                col.push(<td>
                                                                    <Switch loading={this.state[`urgentLoading${details["_id"]}`]} className={`cm_ckeckbox_wrapper cm_ckeckbox_wrapper_height ${details["isUrgent"] === true ? 'cm_active' : 'cm_inactive'}`}
                                                                        onClick={(e) => this.handleChangeStatusUrgent(details["_id"], details["isUrgent"], e)}
                                                                        checked={details["isUrgent"] === true}
                                                                    >
                                                                        {/* <span className="cm_ckeckbox_btn"></span> */}
                                                                    </Switch>
                                                                </td>)
                                                            }
                                                            else if (item.dataIndex == "status") {
                                                                col.push(<td className="cm_no_wrap">
                                                                    {
                                                                        <Select
                                                                            className={`cm_min_width min_height_new ${adminRole == 7 && 'read-only'}`}
                                                                            onChange={(e) => this.changeStatus(e.value, details._id)}
                                                                            placeholder="Select..."
                                                                            options={statusOptions.filter((item) => item.value)}
                                                                            value={statusOptions.find(status => status.value === details.status)}
                                                                            optionClassName="option-AS"
                                                                            menuPlacement="auto"
                                                                            classNamePrefix="custom"
                                                                            isDisabled={adminRole == 7 ? true : false}
                                                                        />}
                                                                </td>

                                                                )
                                                            }
                                                            else if (item.dataIndex === "requestId") {
                                                                col.push(<td>
                                                                    {
                                                                        (isRequestIdLinked && details.requestServiceData) && (adminRole != 7) &&
                                                                        <Link to={`/service/${details.requestServiceData["userId"]}/productService`}>{details[item.dataIndex]} </Link>
                                                                        ||
                                                                        details[item.dataIndex]
                                                                    }
                                                                </td>);
                                                            }
                                                            else if (item.dataIndex === "_id") {
                                                                col.push(<td><Link to="#" onClick={() => this.onJobClick(details["_id"])} >{details["_id"]}</Link></td>);
                                                            }
                                                            else if (item.dataIndex === "isRescheduled") {
                                                                col.push(<td className="cm_no_wrap"> {details["isRescheduled"] && details["isRescheduled"] == true && "True" || "False"}</td>)
                                                            }
                                                            else if (item.dataIndex === "tatDate" && adminRole != 7) {
                                                                let dateToRender = moment(details["created"]).add(Number(details["requestServiceData"] && details["requestServiceData"]["pincodeData"] && details["requestServiceData"]["pincodeData"].tat) || 0, "days").format('Do MMM YY, h:mm:ss a');
                                                                col.push(<td className="cm_no_wrap"> {dateToRender}</td>)
                                                            }
                                                            else if (item.dataIndex === "inTat" && adminRole != 7) {
                                                                let calculteTatDay = moment(details["closeDate"]).diff(details["created"], 'days');
                                                                var tat_day = details && details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"].tat : 0;
                                                                col.push(<td className="cm_no_wrap"> {calculteTatDay <= tat_day ? "In Tat" : "Out Tat"}</td>)
                                                            }
                                                            else if (item.dataIndex === "customerId") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] && details && details["requestServiceData"] ?
                                                                            <span onClick={() => this.onCustomerClick(details["customerData"]["_id"])} >{details["requestServiceData"]["firstName"]} {details["requestServiceData"]["lastName"]}</span>
                                                                            : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "customerEmail") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] ? details["customerData"]["email"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "isOneFitPlusDownloadbyVendor") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] && details["customerData"]["isOneFitPlusDownloadbyVendor"]==true && "True" || "False"}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "isFitWarzDownloadbyVendor") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] && details["customerData"]["isFitWarzDownloadbyVendor"]==true && "True" || "False"}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "isTrainerAssigned") {
                                                                col.push(
                                                                    <td>
                                                                        {details["customerData"] && details["customerData"]["isTrainerAssigned"]==true && "True" || "False"}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "isCustomerAcceptedFee") {
                                                                col.push(
                                                                    <td>
                                                                        {details["isCustomerAcceptedFee"]==true && "True" || "False"}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "customerPhNO") {
                                                                col.push(
                                                                    <td>
                                                                        {details["requestServiceData"] ? details["requestServiceData"]["mobileNo"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "customerAltPhNO") {
                                                                col.push(
                                                                    <td>
                                                                        {details["requestServiceData"] ? details["requestServiceData"]["alternatePhoneNo"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "customerAddress") {
                                                                col.push(
                                                                    <td>
                                                                        {details["requestServiceData"] ? details["requestServiceData"]["mailingStreet"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "city") {
                                                                col.push(<td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]['city'] : null}</td>);
                                                            }
                                                            else if (item.dataIndex === "state") {
                                                                col.push(<td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]["state"] : null}</td>);
                                                            }
                                                            else if (item.dataIndex === "pincode") {
                                                                col.push(<td>{details["requestServiceData"] && details["requestServiceData"]["pincodeData"] ? details["requestServiceData"]["pincodeData"]["pincode"] : null}</td>);
                                                            }
                                                            else if (item.dataIndex === "remark") {
                                                                col.push(<td> <span className="cm_table_three_dots" onClick={() => this.openRemarkView(details["remark"])}> {details["remark"] ? details["remark"] : null} </span></td>)
                                                            }
                                                            else if (item.dataIndex === "images") {
                                                                col.push(
                                                                    (details && ((details.afterImages && details.afterImages.length > 0) || (details.beforeImages && details.beforeImages.length > 0))) ?
                                                                        <td className="cm_no_wrap"><button className="btn btn-info btn-sm" onClick={() => this.openImageVideoView(details)}>View</button></td>
                                                                        :
                                                                        (details && ((details.afterImage) || (details.beforeImage))) ?
                                                                            <td className="cm_no_wrap"><button className="btn btn-info btn-sm" onClick={() => this.openImageVideoView(details)}>View</button></td>
                                                                            :
                                                                            <td>N/A</td>
                                                                )
                                                            }
                                                            else if (item.dataIndex === "brand") {
                                                                col.push(
                                                                    <td>
                                                                        {details["requestServiceData"] ? details["requestServiceData"]["company"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "model") {
                                                                col.push(
                                                                    <td>
                                                                        {details["requestServiceData"] ? details["requestServiceData"]["modelNo"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex === "spareReceived") {
                                                                col.push(
                                                                    <td>
                                                                        {details["requestServiceData"] ? details["requestServiceData"]["spareReceived"] : null}
                                                                    </td>
                                                                );
                                                            }
                                                            else if ((item.dataIndex === "noteData.notes_unread_count") && (adminRole != 7)) {
                                                                col.push(
                                                                    <td>
                                                                        <button className="btn btn-dark btn-sm" onClick={() => this.handleAddProductJobNotesPopup(details)} >
                                                                            Add
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <button className={details['noteData'] && details['noteData']['notes_unread_count'] > 0 ? 'btn btn-sm btn-success' : 'btn btn-sm btn-dark'} onClick={() => this.handleShowProductJobNotesPopup(details)} >
                                                                            View {details["noteData"] && details["noteData"]["notes_unread_count"] > 0 ? '(' + details["noteData"]["notes_unread_count"] + ')' : null}
                                                                        </button>
                                                                    </td>
                                                                );
                                                            } else if ((item.dataIndex === "internalNoteData.notes_unread_count")) {
                                                                col.push(
                                                                    <td>
                                                                        <button className="btn btn-dark btn-sm" onClick={() => this.handleAddProductJobNotesPopup(details, 'internalNotes')} >
                                                                            Add
                                                                        </button>
                                                                        &nbsp;&nbsp;
                                                                        <button className={details['internalNoteData'] && details['internalNoteData']['notes_unread_count'] > 0 ? 'btn btn-sm btn-success' : 'btn btn-sm btn-dark'} onClick={() => this.handleShowProductJobNotesPopup(details, 'internalNotes')} >
                                                                            View {details["internalNoteData"] && details["internalNoteData"]["notes_unread_count"] > 0 ? '(' + details["internalNoteData"]["notes_unread_count"] + ')' : null}
                                                                        </button>
                                                                    </td>
                                                                );
                                                            }
                                                            else if (item.dataIndex != "action") {
                                                                if (adminRole == 7 && item.role == false) {

                                                                } else {
                                                                    col.push(
                                                                        <td><span className="cm_table_three_dots">
                                                                            {
                                                                                item.parentDataIndex ? details[item.parentDataIndex] && getValue(item.type, details[item.parentDataIndex][item.dataIndex]) || "" : item.dataIndex == "status" ? statusOptions.find(o => o.value === details[item.dataIndex]) && statusOptions.find(o => o.value === details[item.dataIndex]).label : getValue(item.type, details[item.dataIndex])}</span>
                                                                        </td>
                                                                    )
                                                                }
                                                            }
                                                            else if (item.dataIndex === "action" && adminRole != 7) {
                                                                col.push(<td className="cm_no_wrap"> <button className="btn btn-dark btn-sm" onClick={() => this.handleAddProductJobPopup(details)} >Edit</button>

                                                                    &nbsp;&nbsp;
                                                                    <button className="btn btn-danger btn-sm" onClick={(e) => this.openDeleteProductJobPopup(e, details)}>Delete</button>

                                                                </td>)
                                                            }
                                                            return col

                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <Pagination
                                activePage={pageNo}
                                itemsCountPerPage={count}
                                totalItemsCount={totalProductJob}
                                pageRangeDisplayed={3}
                                onChange={this.handlePageChange}
                            />

                            {!productJobList.length ? <h3 className="empty_error">Sorry, we couldn't find any content {search ? 'for' : "!"} <span>{search}</span></h3> : null}
                        </div>

                        {(this.state["selectedJobId"]) && < Card className="mt-5">
                            <Card.Header>
                                <h4 className="cm_page_heading">Service Incident Manangement</h4>
                            </Card.Header>
                            <Card.Body>
                                <ProductServiceIncident
                                    adminRole={adminRole}
                                    loader={loader}
                                    requestId={requestId}
                                    jobId={this.state.getServiceIncidentFromJobId} />
                            </Card.Body>
                        </Card>}
                    </div>

                </div>

                <Modal show={addProductJobPopup} onHide={this.handleClose} centered size="lg" backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>{this.state.selectedId ? "Edit Job" : "Add New Job"}</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body >
                        <Form noValidate validated={validated} onSubmit={(e) => { !this.state.selectedId ? this.handleSubmit(e) : this.handleEditSubmit(e) }} className="d-flex justify-content-center">
                            <div className="col-md-9">
                                {!isFromUser && <FormGroup className="form-group row">
                                    <FormLabel className="col-md-4">Request Id</FormLabel>
                                    <FormControl
                                        maxLength={30}
                                        className="form-control col-md-6"
                                        name="requestId"
                                        type="text"
                                        required
                                        onChange={(e) => this.handleChange(e, "", "RequestId", 30)}
                                        value={this.state["requestId"]}
                                        placeholder="Request Id"


                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel className="col-md-4"></FormLabel>
                                        <FormLabel className="col-md-6"> {"Request Id is required"}</FormLabel>

                                    </Form.Control.Feedback>
                                </FormGroup>
                                }
                                {!isFromUser && <FormGroup className="form-group row">
                                    <FormLabel className="col-md-4">Issue</FormLabel>
                                    <FormControl
                                        maxLength={30}
                                        className="form-control col-md-6"
                                        name="issue"
                                        type="text"
                                        onChange={(e) => this.handleChange(e, "", "issue", 30)}
                                        value={this.state["issue"]}
                                        placeholder="Issue"


                                    ></FormControl>
                                </FormGroup>
                                }
                                {/* {<FormGroup className="form-group row">
                                    <FormLabel className="col-md-4">Job Type</FormLabel>
                                    <FormControl
                                        minLength={3}
                                        maxLength={50}
                                        className="form-control col-md-6"
                                        name="jobTypeId"
                                        type="text"
                                        required
                                        onChange={(e) => this.handleChange(e, "", "job Type", 30)}
                                        value={this.state["jobTypeId"]}
                                        placeholder="Job TypeId"

                                    ></FormControl>
                                    <Form.Control.Feedback type="invalid">
                                        <FormLabel className="col-md-4"></FormLabel>
                                        <FormLabel className="col-md-6"> {"Job TypeId is required"}</FormLabel>

                                    </Form.Control.Feedback>

                                </FormGroup>
                                } */}
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Vendor</FormLabel>
                                        <AsyncSelect
                                            cacheOptions
                                            className="col-md-6 pl-0 pr-0"
                                            require={true}
                                            value={this.state["selectedVendor"]}
                                            loadOptions={this.loadOptionByval}
                                            onFocus={() => this.loadOptionByval()}
                                            defaultOptions={vendors}
                                            onChange={(val) => this.vendorSelect(val)}
                                        />
                                        <Form.Control.Feedback type="invalid" style={{ display: !this.state.vendorId && "block" || "none" }}>
                                            <FormLabel className="col-md-4"></FormLabel>
                                            <FormLabel className="col-md-6"> {validated && !this.state.vendorId && "Please select vendor"}</FormLabel>
                                        </Form.Control.Feedback> </FormGroup>
                                }
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Associate Engineer</FormLabel>
                                        <AsyncSelect
                                            className="col-md-6 pr-0 pl-0"
                                            isDisabled={false}
                                            value={this.state["selectedAssociate"]}
                                            cacheOptions
                                            defaultOptions={associateEngineers}
                                            loadOptions={this.loadAssociateEngineer}
                                            onChange={(val) => this.engineerSelect(val)}
                                        />
                                    </FormGroup>
                                }
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Product Service Type</FormLabel>
                                        <Select
                                            className="col-md-6 pr-0 pl-0"
                                            onChange={(e) => this.handleChange(e.value, "productServiceType")}
                                            placeholder="Select..."
                                            options={productServiceTypeOptions.filter((item) => item.value)}
                                            value={productServiceTypeOptions.find(productServiceType => productServiceType.value && productServiceType.value === this.state["productServiceType"])}
                                        />
                                    </FormGroup>
                                }
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Payment Type</FormLabel>
                                        <Select
                                            className="col-md-6 pr-0 pl-0"
                                            onChange={(e) => this.handleChange(e.value, "paymentType")}
                                            placeholder="Select..."
                                            options={paymentTypeOptions.filter((item) => item.value)}
                                            value={paymentTypeOptions.find(paymentType => paymentType.value && paymentType.value === this.state["paymentType"])}
                                        />
                                    </FormGroup>
                                }
                                {!isFromUser && <FormGroup className="form-group row">
                                    <FormLabel className="col-md-4">Amount</FormLabel>
                                    <FormControl
                                        maxLength={20}
                                        className="form-control col-md-6"
                                        name="jobAmount"
                                        type="text"
                                        onChange={(e) => this.handleChange(e, "", "jobAmount", 20)}
                                        value={this.state["jobAmount"]}
                                        placeholder="jobAmount"
                                    ></FormControl>
                                </FormGroup>
                                }
                                {
                                    <FormGroup className="form-group row">
                                        <FormLabel className="col-md-4">Status</FormLabel>
                                        <Select
                                            className="col-md-6 pr-0 pl-0"
                                            onChange={(e) => this.handleChange(e.value, "status")}
                                            placeholder="Select..."
                                            options={statusOptions.filter((item) => item.value)}
                                            value={statusOptions.find(status => status.value && status.value === this.state["status"])}
                                        />
                                        <Form.Control.Feedback type="invalid" style={{ display: !this.state["status"] && "block" || "none" }}>
                                            <FormLabel className="col-md-4"></FormLabel>
                                            <FormLabel className="col-md-6"> {validated && !this.state["status"] && "Status is required"}</FormLabel>

                                        </Form.Control.Feedback>
                                    </FormGroup>
                                }
                                {error && <Row className="justify-content-center">
                                    <p className="text-center mb-2  error_message">{error}</p>

                                </Row>
                                }
                                <Row className="justify-content-center">
                                    <Button variant="danger" onClick={this.handleClose}>Cancel</Button>
                                    {console.log('-------', addNewJobLoader, productjobValidation)}
                                    <Button type="submit" className="ml-2" variant="success" disabled={addNewJobLoader}>
                                        {!addNewJobLoader ? <span>Submit</span> :
                                            <div>
                                                <i className="fa fa-spinner fa-pulse fa-2x fa-fw margin-bottom"></i>
                                                <span>Submitting....</span>
                                            </div>
                                        }
                                    </Button>

                                </Row>
                            </div>
                        </Form>

                    </Modal.Body>
                </Modal >

                <PopUpModal isOpen={this.state["showHideExport"]} closeModal={() => this.setState({ showHideExport: false })} submit={this.exportHandler} message={`Are you sure to export`} submitTitle="Export" />

                <Modal show={importExcelPopup} onHide={this.closeModal} backdrop="static" centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Upload File!</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Dropzone multiple={false} onDrop={(acceptedFiles, rejectedFiles) => this.handleFileDrop(acceptedFiles, rejectedFiles)} accept='.xlsx, .xls, .csv'>
                            {({ getRootProps, getInputProps, isDragActive, acceptedFiles }) => (
                                <section>
                                    <div {...getRootProps()} className="dropzone text-center"> <input {...getInputProps()} />
                                        <i className="fa fa-cloud-upload" />
                                        {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ? <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Modal.Body>

                    {validationSuccess && <Alert variant="success"> {validationSuccess} </Alert>}
                    {validationError && <Alert variant="danger"> {validationError} </Alert>}
                    {badEntryErrors.length > 0 && <Alert variant="danger">
                        {badEntryErrors.map(err => {
                            return (
                                <>
                                    <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                                </>
                            )
                        })}
                    </Alert>}


                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Cancel</Button>
                        <Button variant="success" onClick={this.submitValidateExcelUpload} disabled={validationError || isUploading} size="sm">
                            {isUploading ? <><Spinner animation="border" variant="warning" size="sm" /> Uploading..</> : 'Upload'}
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.remarkViewPopup} backdrop="static" onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Remark</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div> {this.state.displayRemark} </div>
                    </Modal.Body>
                </Modal>


                <Popup
                    open={deleteProductJobPopup}
                    closeOnDocumentClick={false}
                    onClose={this.closeModal}

                >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h5 className="text-center mb-4">Are you sure, you want to delete this Job?</h5>
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-danger btn-sm btn-block" onClick={this.deleteProductJob}>Delete</button>
                            </div>

                            <div className="col">
                                <button className="btn btn-warning btn-sm btn-block" onClick={this.closeModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Popup>

                <PopUpModal isOpen={assignEnigneerSelected} closeModal={this.assignEnigneerSelect} submit={this.assignJobToEngineer} message={`Are you sure to assign selected jobs to ${assignEnigneerSelectedName && assignEnigneerSelectedName.label} ?`} validationError={validationError} />
                <PopUpModal isOpen={statusPopup} closeModal={this.closeStatusPopup} submit={this.changeJobStaus} message={`Are you sure to change the status of job`} submitTitle="Update Status" />


                {imagesViewPopup && <Modal show={imagesViewPopup} backdrop="static" onHide={this.closeModal} centered size="lg">
                    <Modal.Header closeButton>
                        <Modal.Title><h6>Job Images/Videos</h6> </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="card card_style">
                            <div className="card-body  text-center">
                                <div className="form-group">
                                    <div className='mt-2'>
                                        <p><b>Before Image/Video</b></p>
                                        {jobImages.beforeImages.length > 0 ?
                                            <div className="img_gallery">
                                                <div className="sidereel">
                                                    {jobImages.beforeImages && jobImages.beforeImages.length > 0 && jobImages.beforeImages.map(file => (
                                                        <>
                                                            {(file && file.file_type == 'image') || (file && file.file_type == 'image/jpeg') || (file && file.file_type && file.file_type.includes("image")) ?
                                                                <div className="imagereel position-relative" onClick={() => this.openBeforeImageVideoView(file)}>
                                                                    <img title='Before Image' src={file.url} />
                                                                    <i class="fa fa-eye"></i>
                                                                </div>
                                                                :
                                                                <div className="videoreel" onClick={() => this.openBeforeImageVideoView(file)}>
                                                                    <img title='Before Video' src={file.thumbnail} />
                                                                    <i class="fa fa-video-camera"></i>
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                                <div className="fullimgslider">
                                                    {/* {console.log("file-----type-->", jobImages.before_file_type)} */}
                                                    {jobImages.before_file_type == 'video' || jobImages.before_file_type == 'video/mp4' ?
                                                        <video
                                                            className="cm_video"
                                                            src={jobImages.before_video_url}
                                                            type={jobImages.before_video_mimetype}
                                                            poster={jobImages.before_img_url}
                                                            controls
                                                        />
                                                        :
                                                        <img title='Before Image' src={jobImages.before_img_url} />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            jobImages.beforeImage ?
                                                <>
                                                    <div className="img_gallery">
                                                        <div className="sidereel">
                                                            <div className="imagereel">
                                                                <img title='Before Image' src={jobImages.beforeImage} />
                                                            </div>
                                                        </div>
                                                        <div className="fullimgslider">
                                                            <img title='Before Image' src={jobImages.beforeImage} />
                                                        </div>
                                                    </div>
                                                </>
                                                : 'N/A'
                                        }
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className='mt-2'>
                                        <p><b>After Image/Video</b></p>
                                        {jobImages.afterImages.length > 0 ?
                                            <div className="img_gallery">
                                                <div className="sidereel">
                                                    {jobImages.afterImages && jobImages.afterImages.map(file => (
                                                        <>
                                                            {file && file.file_type == 'image' || file && file.file_type == 'image/jpeg' || (file && file.file_type && file.file_type.includes("image")) ?
                                                                <div className="imagereel position-relative" onClick={() => this.openAfterImageVideoView(file)}>
                                                                    <img title='Before Image' src={file.url} />
                                                                    <i class="fa fa-eye"></i>
                                                                </div>
                                                                :
                                                                <div className="videoreel" onClick={() => this.openAfterImageVideoView(file)}>
                                                                    <img title='Before Video' src={file.thumbnail} />
                                                                    <i class="fa fa-video-camera"></i>
                                                                </div>
                                                            }
                                                        </>
                                                    ))}
                                                </div>
                                                <div className="fullimgslider">

                                                    {jobImages.after_file_type == 'video' || jobImages.after_file_type == 'video/mp4' ?
                                                        <>
                                                            <p></p>
                                                            <video
                                                                className="cm_video"
                                                                src={jobImages.after_video_url}
                                                                type={jobImages.after_video_mimetype}
                                                                poster={jobImages.after_img_url}
                                                                controls
                                                            />
                                                        </>
                                                        :
                                                        <img title='Before Image' src={jobImages.after_img_url} />
                                                    }
                                                </div>
                                            </div>
                                            :
                                            jobImages.afterImage ?
                                                <>
                                                    <div className="img_gallery">
                                                        <div className="sidereel">
                                                            <div className="imagereel">
                                                                <img title='Before Image' src={jobImages.afterImage} />
                                                            </div>
                                                        </div>
                                                        <div className="fullimgslider">
                                                            <img title='Before Image' src={jobImages.afterImage} />
                                                        </div>
                                                    </div>
                                                </>
                                                : 'N/A'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="danger" onClick={this.closeModal} size="sm">Close</Button>
                    </Modal.Footer>
                </Modal>}

                <Popup open={this.state.addProductJobNotesPopup} closeOnDocumentClick={false} onClose={this.closeModal} >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">Add Note</h3>
                        <div className="msg_box">

                            <Form onSubmit={this.addJobNoteAction}>
                                <div className='row'>
                                    <FormGroup className='col-12'>
                                        <FormLabel>Message {this.state.isFoundErrorNoteMessage ? <span className='error_message'> Please enter message </span> : ''} </FormLabel>
                                        <Form.Control
                                            as="textarea"
                                            placeholder="Message"
                                            value={this.state["noteMessage"]}
                                            name='noteMessage'
                                            onChange={this.handleChange}
                                            required
                                            rows={3}
                                        />
                                    </FormGroup>
                                </div>

                                <Row className="justify-content-center">
                                    <Button variant="danger" onClick={this.closeModal}>Cancel</Button>
                                    <Button className="ml-2" variant="success" type="submit" disabled={this.state["addNoteloader"]} >
                                        {this.state["addNoteloader"] ? 'Wait' : 'Add'}
                                    </Button>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Popup>

                <Popup open={this.state.showProductJobNotesPopup} closeOnDocumentClick={false} onClose={this.closeModal} >
                    <div className="cm_modal">
                        <span className="cm_modal_close" onClick={this.closeModal}>&times;</span>
                        <h3 className="text-center">All Notes</h3>
                        <div className="msg_box">

                            {this.state.jobNoteLists && this.state.jobNoteLists.map(note =>
                                <div className="msg_card">
                                    <h6>{note.notedBy[0] && note.notedBy[0].firstName ? `${note.notedBy[0].firstName} ${note.notedBy[0].lastName}` : ''}</h6>
                                    <time>{moment(note.created).format('Do MMM YYYY H:mm')} </time>
                                    <span className={`msg_status review'`}> {note.state} </span>
                                    <p className='text-break'>{note.content}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </Popup>
            </HOC >
        )
    }
}


export default ProductJob;

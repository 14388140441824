const Menus = [
    { value: "Dashboard", label: "Dashboard", title: "Dashboard", path: "dashboard", iconClass: "fa fa-home" },
    { level1: 'CRM', value: "Users", label: "Users", title: "Users", path: "users", iconClass: "fa fa-users" },
    { level1: 'CRM', value: "Orders", label: "Orders", title: "Orders", path: "orders", iconClass: "fa fa-tasks" },
    { level1: 'CRM', value: "Deliverable Pincode", label: "Deliverable Pincode", title: "Deliverable Pincode", path: "deliverablePincode", iconClass: "fa fa-tasks" },
    { level1: 'CRM', value: "Reward Transactions", label: "Reward Transactions", title: "Reward Transactions", path: "rewardTransactions", iconClass: "fa fa-credit-card" },
    { level1: 'CRM', value: "Vendors", label: "Vendors", title: "Vendors", path: "vendors", iconClass: "fa fa-user-secret" },
    { level1: 'CRM', value: "Pincode", label: "Pincode", title: "Pincode", path: "pincode", iconClass: "fa fa-map-pin" },
    { level1: 'CRM', value: "Product Jobs", label: "Product Jobs", title: "Product Jobs", path: "productJob", iconClass: "fa fa-tasks" },
    { level1: 'CRM', value: "Service", label: "Service", title: "Service", path: "adminService", iconClass: "fa fa-gear" },
    { level1: 'CRM', value: "CRM Agent", label: "CRM Agent", title: "CRM Agent", path: "crmAgent", iconClass: "fa fa-gear" },
    // { level1: 'CRM', value: "Stores", label: "Stores", title: "Stores", path: "stores", iconClass: "fa fa-gear" },
    // { level1: 'Delivery', value: "Delivery Riders", label: "Delivery Riders", title: "Delivery Riders", path: "deliveryRiders", iconClass: "fa fa-motorcycle" },
    // { level1: 'Delivery', value: "Stations", label: "Stations", title: "Stations", path: "stations", iconClass: "fa fa-flag-checkered" },
    // { level1: 'EComm', value: "Guaranteed Plan Features", label: "Guaranteed Plan Features", title: "Guaranteed Plan Features", path: "guaranteedPlanFeatures", iconClass: "fa fa-check-square-o" },
    // { level1: 'EComm', value: "Order Management", label: "Order Management", title: "Order Management", path: "orderManagement", iconClass: "fa fa-archive" },
    // { level1: 'EComm', value: "L1 L2", label: "L1 L2", title: "L1 L2", path: "locationsAvailable", iconClass: "fa fa-map-marker" },
    { level1: 'HealthServices', level2: 'Diet', value: "Dieticians", label: "Dieticians", title: "Dieticians", path: "dieticians", iconClass: "fa fa-user-md" },
    { level1: 'HealthServices', level2: 'Diet', value: "Food Categories", label: "Food Categories", title: "Food Categories", path: "food-category", iconClass: "fa fa-cubes" },
    { level1: 'HealthServices', level2: 'Diet', value: "Food items", label: "Food items", title: "Food items", path: "foods", iconClass: "fa fa-cutlery" },
    { level1: 'HealthServices', level2: 'Diet', value: "Questionaire", label: "Questionaire", title: "Questionaire", path: "questionaire", iconClass: "fa fa-question" },
    { level1: 'HealthServices', level2: 'Fitness', value: "Exercise Category", label: "Exercise Category", title: "Exercise Category", path: "exercise-category", iconClass: "fa fa-tags" },
    { level1: 'HealthServices', level2: 'Fitness', value: "Exercise", label: "Exercise", title: "Exercise", path: "exercise", iconClass: "fa fa-universal-access" },
    { level1: 'HealthServices', level2: 'Configure', value: "Categories TimeSlot", label: "Categories TimeSlot", title: "Categories TimeSlot", path: "categoriesTimeslot", iconClass: "fa fa-street-view" },
    { level1: 'HealthServices', level2: 'Configure', value: "Service Categories", label: "Service Categories", title: "Service Categories", path: "serviceCategories", iconClass: "fa fa-street-view" },
    { level1: 'HealthServices', level2: 'Configure',  value: "Service Banners", label: "Service Banners", title: "Service Banners", path: "serviceBanners", iconClass: "fa fa-picture-o" },
    { level1: 'HealthServices', level2: 'Diet', value: "Diet Plan Templates", label: "Diet Plan Templates", title: "Diet Plan Templates", path: "dietPlanTempelate", iconClass: "fa fa-cutlery" },
    { level1: 'HealthServices', level2: 'Fitness', value: "Fitness Plan Templates", label: "Fitness Plan Templates", title: "Fitness Plan Templates", path: "fitnessPlanTemplate", iconClass: "fa fa-bicycle" },
    { level1: 'HealthServices', level2: 'Diet', value: "Diet Plans", label: "Diet Plans", title: "Diet Plans", path: "dietPlans", iconClass: "fa fa-cutlery" },
    { level1: 'HealthServices', level2: 'Diet', value: "Smart Diet Plans", label: "Smart Diet Plans", title: "Smart Diet Plans", path: "smartdietPlans", iconClass: "fa fa-cutlery" },
    { level1: 'HealthServices', level2: 'Fitness', value: "Video Sessions", label: "Video Sessions", title: "Video Sessions", path: "videoSessions", iconClass: "fa fa-video-camera" },
    { level1: 'HealthServices', level2: 'Fitness', value: "Help Video Categories", label: "Help Video Categories", title: "Help Video Categories", path: "helpVideoCategory", iconClass: "fa fa-video-camera" },
    { level1: 'HealthServices', level2: 'Fitness', value: "Help Videos", label: "Help Videos", title: "Help Videos", path: "helpVideos", iconClass: "fa fa-youtube" },
    { level1: 'Live', value: "Streaming2", label: "Streaming", title: "Streaming", path: "streaming2", iconClass: "fa fa-video-camera" },
    { level1: 'Live', value: "StreamingOld", label: "Streaming Old", title: "Streaming Old", path: "streaming", iconClass: "fa fa-video-camera" },
    { level1: 'Live', value: "Ads", label: "Ads", title: "Ads", path: "ads", iconClass: "fa fa-video-camera" },
    { level1: 'Setup', level2: 'Catalogue', value: "Products", label: "Products", title: "Products", path: "products", iconClass: "fa fa-shopping-bag" },
    { level1: 'Setup', level2: 'Customers', value: "Coupons", label: "Coupons", title: "Coupons", path: "coupons", iconClass: "fa fa-scissors" },
    { level1: 'Setup', level2: 'Catalogue', value: "Device", label: "Device", title: "Device", path: "device", iconClass: "fa fa-cog" },
    { level1: 'Setup', level2: 'Catalogue', value: "Inventory", label: "Inventory", title: "Inventory", path: "inventory", iconClass: "fa fa-gear" },
    { level1: 'Setup', level2: 'Catalogue', value: "Product Coupon", label: "Product Coupon", title: "Product Coupon", path: "coupon", iconClass: "fa fa-gear" },
    { level1: 'Setup', level2: 'FitServ', value: "Trainers", label: "Trainers", title: "Trainers", path: "trainers", iconClass: "fa fa-heartbeat" },
    { level1: 'Setup', level2: 'FitServ', value: "Holidays", label: "Holidays", title: "Holidays", path: "holidays", iconClass: "fa fa-calendar-check-o" },
    { level1: 'Setup', level2: 'Access', value: "Sub Admins", label: "Sub Admins", title: "Sub Admins", path: "subAdmins", iconClass: "fa fa-user-secret" },
    { level1: 'Setup', level2: 'Access', value: "Roles", label: "Roles", title: "Roles", path: "roles", iconClass: "fa fa-id-card" },
    { level1: 'Setup', level2: 'Customers', value: "Customer Types", label: "Customer Types", title: "Customer Types", path: "customerTypes", iconClass: "fa fa-id-card-o" },
    { level1: 'Setup', level2: 'Customers', value: "Service Policy", label: "Service Policy", title: "Service Policy", path: "servicePolicy", iconClass: "fa fa-street-view" },
    { level1: 'Setup', level2: 'HealthCoins', value: "Coins", label: "Coins", title: "Coins", path: "coins", iconClass: "fa fa-money" },
    { level1: 'Setup', level2: 'HealthCoins', value: "Reward Products", label: "Reward Products", title: "Reward Products", path: "rewardProducts", iconClass: "fa fa-gift" },
    { level1: 'Setup', level2: 'UrbanTerrain', value: "Outdoor Tracks", label: "Outdoor Tracks", title: "Outdoor Tracks", path: "outdoorTracks", iconClass: "fa fa-road" },
    // { level1: 'Setup', level2: 'Customers', value: "Customer Service Types", label: "Customer Service Types", title: "Customer Service Types", path: "customerServiceTypes", iconClass: "fa fa-id-card-o" },
    { level1: 'Setup', level2: 'Website', value: "Stories", label: "Stories", title: "Stories", path: "stories", iconClass: "fa fa-book" },
    { level1: 'Setup', level2: 'Website', value: "Testimonial", label: "Testimonial", title: "Testimonial", path: "testimonial", iconClass: "fa fa-quote-right" },
    { level1: 'Setup', level2: 'Website', value: "Why Choose Us", label: "Why Choose Us", title: "Why Choose Us", path: "whyChooseUs", iconClass: "fa fa-question-circle-o" },
    { level1: 'Setup', level2: 'Website', value: "Blogs", label: "Blogs", title: "Blogs", path: "blogs", iconClass: "fa fa-rss" },
    { level1: 'Setup', level2: 'Website', value: "Careers", label: "Careers", title: "Careers", path: "careers", iconClass: "fa fa-rss" },

    //{ level1: 'Others', value: "Service Dashboard", label: "Service Dashboard", title: "Service Dashboard", path: "adminDashboard", iconClass: "fa fa-home" },
]
    
module.exports = {
    Menus
}





















/*

var Menus = [
    { value: "Dashboard", label: "Dashboard", title: "Dashboard", path: "dashboard", iconClass: "fa fa-home" },
    // { value: "Service Dashboard", label: "Service Dashboard", title: "Service Dashboard", path: "adminDashboard", iconClass: "fa fa-home" },
    { value: "Users", label: "Users", title: "Users", path: "users", iconClass: "fa fa-users" },
    { value: "Device", label: "Device", title: "Device", path: "device", iconClass: "fa fa-cog" },
    { value: "Coupons", label: "Coupons", title: "Coupons", path: "coupons", iconClass: "fa fa-scissors" },
    { value: "Products", label: "Products", title: "Products", path: "products", iconClass: "fa fa-shopping-bag" },
    { value: "Dieticians", label: "Dieticians", title: "Dieticians", path: "dieticians", iconClass: "fa fa-user-md" },
    { value: "Trainers", label: "Trainers", title: "Trainers", path: "trainers", iconClass: "fa fa-heartbeat" },
    { value: "Food Categories", label: "Food Categories", title: "Food Categories", path: "food-category", iconClass: "fa fa-cubes" },
    { value: "Food items", label: "Food items", title: "Food items", path: "foods", iconClass: "fa fa-cutlery" },

    { value: "Questionaire", label: "Questionaire", title: "Questionaire", path: "questionaire", iconClass: "fa fa-question" },
    { value: "Exercise Category", label: "Exercise Category", title: "Exercise Category", path: "exercise-category", iconClass: "fa fa-tags" },
    { value: "Exercise", label: "Exercise", title: "Exercise", path: "exercise", iconClass: "fa fa-universal-access" },
    { value: "Categories TimeSlot", label: "Categories TimeSlot", title: "Categories TimeSlot", path: "categoriesTimeslot", iconClass: "fa fa-street-view" },
    { value: "Service Categories", label: "Service Categories", title: "Service Categories", path: "serviceCategories", iconClass: "fa fa-street-view" },
    { value: "Service Banners", label: "Service Banners", title: "Service Banners", path: "serviceBanners", iconClass: "fa fa-picture-o" },
    { value: "Diet Plan Templates", label: "Diet Plan Templates", title: "Diet Plan Templates", path: "dietPlanTempelate", iconClass: "fa fa-cutlery" },
    { value: "Fitness Plan Templates", label: "Fitness Plan Templates", title: "Fitness Plan Templates", path: "fitnessPlanTemplate", iconClass: "fa fa-bicycle" },
    { value: "Diet Plans", label: "Diet Plans", title: "Diet Plans", path: "dietPlans", iconClass: "fa fa-cutlery" },
    { value: "Holidays", label: "Holidays", title: "Holidays", path: "holidays", iconClass: "fa fa-calendar-check-o" },
    { value: "Sub Admins", label: "Sub Admins", title: "Sub Admins", path: "subAdmins", iconClass: "fa fa-user-secret" },
    { value: "Roles", label: "Roles", title: "Roles", path: "roles", iconClass: "fa fa-id-card" },
    { value: "Customer Types", label: "Customer Types", title: "Customer Types", path: "customerTypes", iconClass: "fa fa-id-card-o" },
    { value: "Service Policy", label: "Service Policy", title: "Service Policy", path: "servicePolicy", iconClass: "fa fa-street-view" },
    { value: "Video Sessions", label: "Video Sessions", title: "Video Sessions", path: "videoSessions", iconClass: "fa fa-video-camera" },
    { value: "Help Video Categories", label: "Help Video Categories", title: "Help Video Categories", path: "helpVideoCategory", iconClass: "fa fa-video-camera" },
    { value: "Help Videos", label: "Help Videos", title: "Help Videos", path: "helpVideos", iconClass: "fa fa-youtube" },
   
    { value: "Coins", label: "Coins", title: "Coins", path: "coins", iconClass: "fa fa-money" },
    { value: "Reward Products", label: "Reward Products", title: "Reward Products", path: "rewardProducts", iconClass: "fa fa-gift" },
    { value: "Reward Transactions", label: "Reward Transactions", title: "Reward Transactions", path: "rewardTransactions", iconClass: "fa fa-credit-card" },
    { value: "Streaming", label: "Streaming", title: "Streaming", path: "streaming", iconClass: "fa fa-video-camera" },
    // { value: "StreamingDetails", label: "StreamingDetails", title: "StreamingDetails", path: "streamingDetails", iconClass: "fa fa-video-camera" },


    // { value: "Transaction Details", label: "Transaction Details", title: "Transaction Details", path: "transactionDetails", iconClass: "fa fa-credit-card" },
    { value: "vendors", label: "vendors", title: "Vendors", path: "vendors", iconClass: "fa fa-user-secret" },
    { value: "Pincode", label: "Pincode", title: "Pincode", path: "pincode", iconClass: "fa fa-map-pin" },
    { value: "Product Jobs", label: "Product Jobs", title: "Product Jobs", path: "productJob", iconClass: "fa fa-tasks" },
    { value: "Service", label: "Service", title: "Service", path: "adminService", iconClass: "fa fa-gear" },
    { value: "CRM Agent", label: "crmAgent", title: "CRM Agent", path: "crmAgent", iconClass: "fa fa-gear" },
    { value: "Ads", label: "Ads", title: "Ads", path: "ads", iconClass: "fa fa-street-view" },


    // { value: "Delivery Riders", label: "Delivery Riders", title: "Delivery Riders", path: "deliveryRiders", iconClass: "fa fa-motorcycle" },
    // { value: "Stations", label: "Stations", title: "Stations", path: "stations", iconClass: "fa fa-flag-checkered" },
    { value: "Outdoor Tracks", label: "Outdoor Tracks", title: "Outdoor Tracks", path: "outdoorTracks", iconClass: "fa fa-road" },
    // { value: "Customer Service Types", label: "Customer Service Types", title: "Customer Service Types", path: "customerServiceTypes", iconClass: "fa fa-id-card-o" },

    // { value: "Guaranteed Plan Features", label: "Guaranteed Plan Features", title: "Guaranteed Plan Features", path: "guaranteedPlanFeatures", iconClass: "fa fa-check-square-o" },
    // { value: "Blogs", label: "Blogs", title: "Blogs", path: "blogs", iconClass: "fa fa-rss" },
    // { value: "Stories", label: "Stories", title: "Stories", path: "stories", iconClass: "fa fa-book" },
    // { value: "Testimonial", label: "Testimonial", title: "Testimonial", path: "testimonial", iconClass: "fa fa-quote-right" },
    // { value: "Order Management", label: "Order Management", title: "Order Management", path: "orderManagement", iconClass: "fa fa-archive" },
    // { value: "Why Choose Us", label: "Why Choose Us", title: "Why Choose Us", path: "whyChooseUs", iconClass: "fa fa-question-circle-o" },
    // { value: "L1 L2", label: "L1 L2", title: "L1 L2", path: "locationsAvailable", iconClass: "fa fa-map-marker" },
]
    
module.exports = {
    Menus
}

*/
